form.material {
	@import "forms/checkboxes";
	@import "forms/file-input";
	@import "forms/input-fields";
	@import "forms/radio-buttons";
	@import "forms/range";
	@import "forms/select";

	@import "forms/topics";

	.selectized {
		@import "selectize/selectize";
	}

	&.flat, .flat {
		@import "forms/flat";
	}

	footer {
		text-align: right;
	}

	label {
		font-size: $label-font-size;
		color: $label-font-color;
	}

	.required {
		label:after {
			content: " *";
			color: $error-color;
		}
	}
}

#material-datepicker {
	@import "components/datepicker/default";
	@import "components/datepicker/default.date";
	@import "components/datepicker/default.time";
}

@import "forms/dropdown";
@import "forms/filter_tools";
@import "forms/filter_nav";
@import "forms/table-filters";
@import "forms/switches";

.form-helper {
	padding-top: 24px;
	color: rgba(0,0,0,0.5);
	font-style: italic;
}
