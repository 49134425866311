.modal {
	@extend .z-depth-4;
	z-index: 1100;

	background-color: #fafafa;
	padding: 0;

	border-radius: 2px;
	will-change: top, opacity;
	text-align : left;
	white-space: normal;

	.spinner {
		display: none;
		z-index: 9999;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(255, 255, 255, 0.8);
	}

	&.loading .spinner {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	header {
		text-align : left;
		display: block;
	}

	.modal-content {
		padding: 16px;
		min-height: 75px;
	}

	.modal-close {
		cursor: pointer;
	}

	footer, .modal-footer {
		display: block;
		border-radius: 0 0 2px 2px;
		background: #fafafa;
		color: $text-color;
		margin: 0 !important;
		padding: 6px;
		width: 100%;
		text-align: right;
		border-top: 1px solid rgba(0, 0, 0, .1);
		.btn {
			margin: 6px 0;
		}
	}
}

.modal {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	width: 55%;
	margin: auto;
	// max-height: 70%;
	// overflow-y: auto;	// Pour les formulaires de selection de date, empeche les select de s'afficher correctement.

	&.tiny {
		width: 20%;
		@media #{$medium-and-down} {
			width: 40%;
		}
	}

	&.small {
		width: 35%;
		@media #{$medium-and-down} {
			width: 50%;
		}
	}

	&.medium {
		width: 55%;
		@media #{$medium-and-down} {
			width: 70%;
		}
	}

	&.large {
		width: 75%;
		@media #{$medium-and-down} {
			width: 85%;
		}
	}

	&.xlarge {
		width: 95%;
	}

	@media #{$medium-and-down} {
		width: 80%;
	}
}

.modal-overlay {
	position: fixed;
	z-index: 999;
	top: -100px;
	left: 0;
	bottom: 0;
	right: 0;
	height: 125%;
	width: 100%;
	background: #000;
	display: none;

	will-change: opacity;
}
