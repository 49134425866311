
nav.filter,nav.filter-nojs {
	margin: 0 12px 0 3px;
	min-height: 0 !important;
	overflow: visible;
	padding: 0 0 5px 0;
}

.table-filters nav.filter,
.table-filters nav.filter-nojs {
	margin: 0 0;
	padding: 0
}

nav.filter,nav.filter-nojs {
	form .row {
		margin-left: 0;
		margin-right: 0;

		label{
			color: #b3a9a9;
			font-style: italic;
		}

		&:not(.inline) {
			background : #ffffff;
			border     : none;
		}
	}

	form .select-multiple label {
		color: #b3a9a9;
		font-style: italic;
	}

	.searchform {
		background: #fff;
	}

	.chosen-container-multi .chosen-choices li:not(.search-field) {
		display: inline-block;
		height: auto;
		line-height: 12px;
		font-size: 12px;
		font-weight: 500;
		color: $text-color;
		padding: 5px 9px;
		border-radius: 12px;
		background-color: #e4e4e4;
		margin-bottom: 5px;
		margin-right: 5px;

		button {
			padding: 0 0 0 5px;
			&:hover {
				color: color('grey', 'darken-1');
			}
		}
	}

	.chosen-container-multi .chosen-choices li .search-choice-close:before {
		width: 22px;
		padding-left: 5px;
		text-align: left;
	}

	.chosen-container-multi .chosen-choices li .search-choice-close:hover:before {
		background: none;
		color: #f44336;
	}

	.chosen-container-single .chosen-single span {
		color: #555555;
		font-weight: normal;
	}
	.chosen-container .chosen-results li.active-result {
		font-size: 0.875rem;
	}
}
