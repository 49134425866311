.submit {
	display: inline-block;
	border-radius: 4px;
	height: 32px;
	padding: 0;
	margin: 0;
}

.btn/*, button, input[type="submit"]*/ {
	display: inline-block;
	text-align: center;
	border-radius: 4px;
	transition: .3s;
	cursor: pointer;
	outline: none;

	font-size: 14px;
	height: 32px;
	line-height: 32px;
	padding: 0 16px;
	margin: 0 0.15rem 0.15rem;

	vertical-align: middle;
	text-transform: uppercase;
	font-family: "Roboto", sans-serif;
	font-weight: normal;

	background-color: transparent;
	color: $link-color;
	border: 1px solid rgba(0,0,0,0.2);

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		color: $link-color;
		background-color: rgba( 0, 0, 0, .04 );
	}

	&.btn-icon {
		padding: 0 12px;

		> i.icon {
			padding: 0;
		}

		> i.icon-before {
			padding: 0 8px 0 0;
		}

		> i.icon-after {
			padding: 0 0 0 8px;
		}
	}

	&.btn-xsmall {
		font-size: 9px;
		height: 18px;
		line-height: 18px;
	}

	&.btn-small {
		font-size: 12px;
		height: 24px;
		line-height: 24px;
	}

	&.btn-large {
		font-size: 19px;
		height: 38px;
		line-height: 38px;
	}

	&.btn-xlarge {
		font-size: 22px;
		height: 44px;
		line-height: 44px;
	}

	&.btn-block {
		display: block;
		width: 100%;
		margin: 0 0 9px;
	}

	&.btn-floating {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		width: $btnDefault;
		height: $btnDefault;
		padding: 3px 0 0;
		border-radius: 50%;

		background-color: color('blue', 'base');
		&:hover {
			background-color: color('blue', 'lighten-1');
		}

		&.btn-xsmall{
			width: $btnXsmall;
			height: $btnXsmall;
		}

		&.btn-small{
			width: $btnSmall;
			height: $btnSmall;
		}

		&.btn-large {
			width: $bntLarge;
			height:$bntLarge;
		}

		&.btn-xlarge{
			width: $btnXlarge;
			height: $btnXlarge;
		}

	}

	&.btn-primary, &.btn-success, &.btn-info, &.btn-warning, &.btn-danger, &.btn-error, &.btn-inverse,
	&.btn-youtube, &.btn-facebook, &.btn-instagram, &.btn-twitter, &.btn-twitch, &.btn-gplus,
	&.btn-youtuber, &.btn-brand, &.btn-network, &.btn-contained, &.btn-floating {
		color: white;
		border: 1px solid transparent;
		@extend .z-depth-2;
	}


	&.btn-primary {
		background-color: color('blue', 'base');
		&:hover {
			background-color: color('blue', 'lighten-1');
		}
	}

	&.btn-success {
		background-color: color('green', 'base');
		&:hover {
			background-color: color('green', 'lighten-1');
		}
	}

	&.btn-info {
		background-color: color('cyan', 'lighten-1');
		&:hover {
			background-color: color('cyan', 'lighten-2');
		}
	}

	&.btn-warning {
		background-color: color('orange', 'darken-1');
		&:hover {
			background-color: color('orange', 'lighten-1');
		}
	}

	&.btn-danger, &.btn-error {
		background-color: color('red', 'darken-1');
		&:hover {
			background-color: color('red', 'lighten-1');
		}
	}

	&.btn-inverse {
		background-color: color('grey', 'darken-3');
		&:hover {
			background-color: color('grey', 'darken-2');
		}
	}

	&.btn-white {
		background-color: white;
		&:hover {
			background-color: color('grey', 'lighten-4');
		}
	}


	&.btn-facebook {
		background-color: $facebook-color;
		&:hover {
			background-color: lighten($facebook-color, 10%);
		}
	}

	&.btn-youtube {
		background-color: $youtube-color;
		&:hover {
			background-color: lighten($youtube-color, 10%);
		}
	}

	&.btn-twitter {
		background-color: $twitter-color;
		&:hover {
			background-color: lighten($twitter-color, 10%);
		}
	}

	&.btn-instagram {
		background-color: $instagram-color;
		&:hover {
			background-color: lighten($instagram-color, 10%);
		}
	}

	&.btn-twitch {
		background-color: $twitch-color;
		&:hover {
			background-color: lighten($twitch-color, 10%);
		}
	}

	&.btn-gplus{
		background-color: $gplus-color;
		&:hover {
			background-color: lighten($gplus-color, 10%);
		}
	}

	&.btn-youtuber{
		background-color: $youtubers-color;
		&:hover {
			background-color: lighten($youtubers-color, 10%);
		}
	}

	&.btn-brand{
		background-color: $brands-color;
		&:hover {
			background-color: lighten($brands-color, 10%);
		}
	}

	&.btn-network{
		background-color: $networks-color;
		&:hover {
			background-color: lighten($networks-color, 10%);
		}
	}

	&.disabled, &:disabled {
		background-color: color('grey', 'lighten-2');
		color: color('grey', 'base');
		box-shadow: none;
		cursor: not-allowed;
		&:hover {
			background-color: color('grey', 'lighten-2');
		}
	}

	&.btn-flat, &.btn-text {
		box-shadow: none;
		background: none;
		border: 1px solid transparent;
		color: $link-color;
		padding: 0 8px;

		&:hover {
			box-shadow: none;
			background: rgba(55,55,55,0.2); //color('blue-grey', 'lighten-4');
		}

		&.btn-text-inverse {
			color: rgba(255,255,255,0.8);
			&:hover {
				background: rgba(255,255,255,0.2); //color('blue-grey', 'lighten-4');
			}
		}

		&.disabled {
			color: color('grey', 'base');
			background: none;
		}
	}

	&.btn-dots {
		box-shadow: none;
		background: none;
		text-align: center;
		padding: 0;
		border: none;
		color: $text-color;

		&:hover {
			box-shadow: none;
			background: none;
		}

		&:after {
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 28px;
			line-height: 32px;
			width: 32px;
			text-align: center;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;
			content: 'more_vert';
		}

		&.btn-xsmall:after {
			font-size: 15px;
		}

		&.btn-small:after {
			font-size: 17px;
		}

		&.btn-large:after {
			font-size: 30px;
			line-height: 30px;
		}

		&.btn-xlarge:after {
			font-size: 36px;
			line-height: 36px;
		}
	}
}

.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	margin: 0 0.15rem 0.15rem;

	.btn {
		margin: -1px 0 0 -1px;
		padding: 0 12px;
		border: 1px solid #cfcfcf;

		> i:only-child {
			color: $text-color;
		}
	}

	.btn:first-child:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.btn:not(:first-child):not(:last-child) {
		border-radius: 0;
	}

	.btn:last-child:not(:first-child), .dropdown-toggle:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.btn.active {
		background-color: #e0e0e0;
	}
}
