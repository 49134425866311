
.loader {
	background-color: transparent;
	border: none;
	padding: 0;
	position: relative;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.loader-logo-container {
		position: relative;
		margin: 6px auto;
		min-width: 50px;
		max-width: 50px;
        aspect-ratio: 1;
		width: 100%;
		height: 100%;

		.loader-logo {
			@include animation('progress-rotate 2s linear infinite');
			height: 100%;
			width: 100%;
			transform-origin: center center;
			position: absolute;
			top: auto;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;

			.path {
				stroke-dasharray: 1, 200;
				stroke-dashoffset: 0;
				@include animation('progress-dash 1.5s ease-in-out infinite', 'progress-color 6s ease-in-out infinite');
				stroke-linecap: round;
			}
		}
	}

	.loader-text {
		text-align: center;
		margin: 6px auto;
	}
}


.progress-container {
	height: 28px;
	margin: 6px 0;
	position: relative;

	.progress {
		position: absolute;
		top: 0;
		height: 28px;
		border-radius: 5px;
		overflow: hidden;
		left: 0;
		right: 0;

		.progress-before {
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			text-align: right;
			line-height: 28px;
//			padding: 5px;
		}

		.progress-after {
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;

			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			text-align: left;
			line-height: 28px;
			//padding: 5px;
			border-top: 1px solid color( 'grey', 'lighten-2');
		}

		.progress-text {
			padding: 5px;
		}
	}

	&.with-extra-value {
		height: 48px;

		.progress {
			top: 20px;
		}

		.progress-extra-value {
			position: absolute;
			height: 48px;
			top: 0;
			width: 100%;
			z-index: 1;

			.progress-extra-value-before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				color: color('grey', 'darken-1');
				padding-right: 3px;
				text-align: right;
				font-size: 0.8em;
			}

			.progress-extra-value-after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				color: color('grey', 'darken-1');
				border-left: 2px solid color('grey', 'darken-1');
				padding-left: 3px;
				font-size: 0.8em;
			}
		}
	}

	&.with-legend {
		height: 50px;
		.progress-legend .legend-min{
			position: absolute;
			bottom: 0;
			width: 100%;
		}
		.progress-legend .legend-max{
			position: absolute;
			bottom: 0;
			width: 100%;
			text-align: right;
		}
	}

	&.with-legend.with-extra-value {
		height: 72px;
	}
}


.card.card-progress {
	padding-bottom: 8px;
	progress {
		position: absolute;
		bottom: 0;
		width: 100%;
		border: none;
		margin: 0;
		height: 8px;
		display: block;
		appearance: none;
		-webkit-appearance: none;
		border-radius: 0 0 2px 2px;

		&::-webkit-progress-bar {
			background-color: rgba(255, 255, 255, 0.25);
		}

		&::-webkit-progress-value {
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
}

@each $color_name, $color in $colors {
	@each $color_type, $color_value in $color {
		@if $color_type == "base" {
			.card.#{$color_name}.card-progress progress {
				background-color: $color_value !important;
				&::-webkit-progress-bar {
					background-color: lighten($color_value,10%);
				}
				&::-webkit-progress-value {
					background-color: darken($color_value,10%);
				}
			}
		}
		@else if $color_name != "shades" {
			.card.#{$color_name}.#{$color_type}.card-progress progress {
				background-color: $color_value !important;
				&::-webkit-progress-bar {
					background-color: lighten($color_value, 10%);
				}
				&::-webkit-progress-value {
					background-color: darken($color_value, 10%);
				}
			}
		}
	}
}

