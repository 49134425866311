a.dropdown {
	position: relative;

	&:not(.btn-dots) {
		padding-right: 26px; // Laisse la place au symbole

		&:after {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			right: 8px;
			margin-top: -2px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 5px 0 5px;
			border-color: currentColor transparent transparent transparent;
		}
	}
}

.dropdown-content {
	@extend .z-depth-1;
	background-color : $dropdown-bg-color;
	margin           : 0;
	display          : none;
	min-width        : 100px;
	max-height       : 650px;
	opacity          : 0;
	position         : absolute;
	z-index          : 9999;
	will-change      : width, height;
	padding   		  : 0;

}

ul.dropdown-content {
	overflow-y       : auto;

	li {
		clear          : both;
		color          : $text-color;
		cursor         : pointer;
		width          : 100%;
		text-align     : left;
		text-transform : none;
		display 			: list-item;
		padding			: 0;
		font-size		: 16px;
		line-height		: 16px;
		white-space    : nowrap;
		/*list-style-type: none;*/

		&.active /*, &.selected*/ {
			background-color : $dropdown-hover-bg-color;
		}
		&:hover /*, &.active, &.selected*/ {
			background-color : darken($dropdown-hover-bg-color, 5%);
		}

		&.divider {
			min-height : 0;
			height     : 1px;
			margin     : 0;
			padding    : 0;
		}

		&.header:not( :first-of-type ) {
			border-top : 1px solid #e0e0e0;
		}

		&.header {
			color: rgba(0, 0, 0, 0.3);
			padding: 12px 8px 4px;
			cursor:default;
			&:hover {
				background-color: transparent;
			}
		}

		& > a, & > span {
			font-size   : 16px;
			color       : $text-color;
			display     : block;
			padding     : 12px 16px 9px;
		}

		&.disbaled > a, &.optgroup > span {
			color: $input-disabled-color;
		}

		& > span > label {
			top    : 1px;
			left   : 0;
			height : 18px;
		}

		// Icon alignment override
		& > a > i {
			height      : inherit;
			line-height : inherit;
			padding-right: 6px;
		}
	}

	&.dropdown-selectable {
		li {
			position: relative;

			& > a, & > span {
				margin-left: 20px;
				&:after {
					border-radius: 2px;
				}
				&:before, &:after {
					content: '';
					left: 10px;
					top: 10px;
					position: absolute;
					transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
					z-index: 1;
					height: 20px;
					width: 20px;
					background-color: transparent;
				}

				// Unchecked style
				&:not(.checked):before {
					width: 0;
					height: 0;
					border: 3px solid transparent;
					left: 16px;
					top: 22px;

					-webkit-transform: rotateZ(37deg);
					transform: rotateZ(37deg);
					-webkit-transform-origin: 20% 40%;
					transform-origin: 100% 100%;
				}
				&:not(.checked):after {
					height: 20px;
					width: 20px;

					border: 2px solid $radio-empty-color;
					z-index: 0;
				}

				&.checked:before {
					top: 12px;
					left: 11px;
					width: 8px;
					height: 13px;
					border-top: 2px solid transparent;
					border-left: 2px solid transparent;
					border-right: 2px solid $input-background;
					border-bottom: 2px solid $input-background;
					-webkit-transform: rotateZ(37deg);
					transform: rotateZ(37deg);
					-webkit-transform-origin: 100% 100%;
					transform-origin: 100% 100%;
				}

				&.checked:after {
					border: 2px solid $secondary-color;
					background-color: $secondary-color;
					z-index: 0;
				}
			}
		}
	}
}

