.stepper {
	position: relative;
	font-family: "Roboto",sans-serif;
	font-size: 14px;
	margin: 0;
	padding: 0;
	width: 100%;
	border-radius: 2px;

	display: flex;
	flex-direction: row;
	align-items: flex-start;

	.step {
		position: static;
		display: flex;
		justify-content: center;
		align-items: initial;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		height: initial;
		max-height: 84px;
		overflow: hidden;
		list-style: none;


		&:not(:last-child) {
			flex: 1;
		}

		&:not(:last-child):after {
			content: '';
			position: relative;
			flex: 1;
			top: 42px;
			width: 168px;
			margin-left: 0;
			margin-right: 12px;
			height: 1px;
			background-color: rgba(0,0,0,0.1)
		}

		a.step-label:hover {
			background-color: rgba(0,0,0,0.06);
			cursor: pointer;
		}

		.step-label {
			position: relative;
			display: flex;
			align-items: center;

			top: 0;
			flex: initial;
			margin: 0;
			padding: 0 12px 0 12px;
			min-height: 84px;
			transition: 0.025s border-radius linear;

			color:$text-color;

			.step-title {
				display   : flex;
				flex-flow : column nowrap;
				order     : 2;
				width     : initial;

				&>* {
					line-height: 1;
					overflow      : hidden;
				}
			}


			.step-title-message {
//				position: relative;
//				top: 0;
				line-height: 14px;


				position: absolute;
				font-size: 12px;
				opacity: .7;
				font-weight: 400;
				top: 52px;
			}
		}

		.step-label-indicator {
			display: flex;
			order: 1;
			background-color: rgba(0,0,0,0.3);
			border-radius: 100%;
			color: white;
			margin-right: 12px;
			margin-bottom: auto;
			margin-top: auto;

			&>:first-child {
				display         : flex;
				font-size       : 15px;
				width           : 28px;
				height          : 28px;
				align-items     : center;
				justify-content : center;
			}
		}

		&.active {
			.step-title {
				font-weight: bold;
			}

			.step-label-indicator {
				background-color: $link-color;
				color: white;
			}
		}

		&.completed {
			.step-label-indicator {
				background-color: $link-color;
				color: white;
			}
		}
	}
}
