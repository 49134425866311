.tabs-container {
	position: relative;
	height: 48px;
	width: 100%;

	.arrow {
		position: absolute;
		top: 10px;
		z-index: 2;
	}
	.right-arrow {
		right: 2px;
		display: none;
	}
	.left-arrow {
		left: 2px;
		display: none;
	}

	.arrow i {
		color: rgba( 0,0,0,0.3 );
	}
}

ul.nav {
	margin: 0;
	padding: 0;
	white-space: nowrap;
	display: flex;

	&.inverse {
		> li, > li > a {
			color: #cacaca;
			img {
				position: relative;
			}
		}

		> li:hover, > li:hover > a {
			color: #fff;
		}

		> li.active {
			color: #fff;
			background-color:  rgba(255,255,255,0.15);
			border-bottom: 3px solid #fff;
			& > a {
				color: #fff;
			}
		}
	}

	li {
		font-size: 17px;
		line-height: 24px;
		cursor: pointer;

		list-style-position: outside;
		box-sizing: border-box;

		display: inline-block;
		padding: 12px 16px 9px;
		border-bottom: 3px solid transparent;

		i {
			margin-right: 4px;
		}

		img {
			width: 24px;
		}

		&.active {
			border-bottom: 3px solid $navbar-active-color;
			background-color:  $navbar-active-background-color;
		}

		&.static {
			background: rgba( 0, 0, 0, 0.08 );
			border-bottom: 1px solid #ddd;
		}

		&:not( .static ):hover {
			background: rgba( 0, 0, 0, 0.15 );
		}

		&.drop {
			position: relative;
		}

		a {
			color: $text-color;
			text-decoration: none;
			cursor: pointer;
			display: block;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		position: absolute;
		@include transition( all, 0.3s, ease );
		transform: scale(0);
		transform-origin: 0 0;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
		background: #fff;
		z-index: 1000;

		li {
			display: block;
			width: 100%;
			white-space: nowrap;

			&.divider {
				margin: 0;
				height: 0;
				padding: 0;
				border-bottom: 1px solid #ddd;
			}

			a {
				box-sizing: border-box;
			}
		}
	}

	> li.drop { // niveau 1
		padding-right: 32px;
		&:after {
			float: right;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			line-height: 48px;
			content: 'arrow_drop_down';
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			letter-spacing: normal;
			text-transform: none;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;
		}
		&:hover {
			> ul {
				transform: scale(1);
			}
		}

		&.right > ul {
			right: 0;
			left: auto;
		}

		> ul { // Niveau 1
			left: 0;
			top: 48px;

			> li.drop {
				margin-right: 32px;
				&:after {
					font-family: 'Material Icons';
					font-weight: normal;
					font-style: normal;
					font-size: 24px;
					line-height: 1;
					letter-spacing: normal;
					text-transform: none;
					display: inline-block;
					white-space: nowrap;
					word-wrap: normal;
					direction: ltr;
					-webkit-font-feature-settings: 'liga';
					-webkit-font-smoothing: antialiased;
					content: 'chevron_right';
					position: absolute;
					top: 12px;
					right: 12px;
				}
				&:hover {
					> ul {
						transform: scale(1);
					}
				}

				> ul {	// Sous-sous menu
					left: 100%;
					top: 0;
				}
			}
		}
	}
}

// classe suplementaire sur le menu de channel_view et tout les extends

.responsive-nav{
	background-color: white;
	position: relative;
	width: 100%;
	z-index: 101;

	.swiper-slide{
		width: auto;
		> ul {
			min-width: 100%;
		}
	}
}
