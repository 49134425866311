@import '../../../styles/_variables.scss';

.card_container {
    padding: 15px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.logo {
    width: 40%;
    max-width: 100px;
}

.sheet {
        margin-top: 10px;

    .label {
        text-align: right;
        margin-bottom: 5px;
    }

    .value {
        text-align: right;
        font-weight: 500;
        font-size: 20px;
    }
}
