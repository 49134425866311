.flash-message {
	border-bottom: 1px solid #cccccc;
	padding: 12px 16px;
	position: relative;
	background : $info-color;

	.content {
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		background: transparent;
	}

	.flash-icon {
		font-size: 34px;
		color: #ffffff;
		margin-right: 16px;
	}

	.flash-text {
		margin: 10px 0;
		color: white;
		font-size: 20px;
		flex-grow: 1;
	}

	.flash-action {
	}

	&.success {
		background-color: $success-color;
	}
	&.danger, &.error {
		background-color: $error-color;
	}
	&.warning {
		background-color: $warning-color;
	}
	&.info {
		background-color: $info-color;
	}
}

body.no-sidebar {
	.flash-message .content {
		margin: 0 auto;
		padding: 0 8px;
	}

	@media #{$medium-and-up} {
		.flash-message .content {
			width : 95%;
		}
	}

	@media #{$extra-large-and-up} {
		.flash-message .content {
			width : 85%;
			max-width: 1280px;
		}
	}

	@media #{$double-extra-large-and-up} {
		.flash-message .content {
			width : 80%;
			max-width: 1280px;
		}
	}
}

