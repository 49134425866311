/* Switch
   ========================================================================== */

.switch,
.switch *,
.input-switch,
.input-switch * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;

	label {
		cursor: pointer;

		input[type=checkbox] {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .lever {
				background-color: $switch-checked-lever-bg;

				&:after {
					background-color: $switch-bg-color;
					left: 24px;
				}
			}
		}

		.lever {
			content: "";
			display: inline-block;
			position: relative;
			width: 40px;
			height: 15px;
			background-color: $switch-unchecked-lever-bg;
			border-radius: $switch-radius;
			transition: background 0.3s ease;
			vertical-align: middle;
			margin: 0 16px;

			&:after {
				content: "";
				position: absolute;
				display: inline-block;
				width: 21px;
				height: 21px;
				background-color: $switch-unchecked-bg;
				border-radius: 21px;
				box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
				left: -5px;
				top: -3px;
				transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
			}
		}

		&.disabled {
			color: $input-disabled-color;

			input[type=checkbox] {
				&:checked + .lever {
					background-color: $switch-disabled-checked-lever-bg;
				}
			}

			.lever {
				background-color: color( 'grey', 'lighten-2' );
				&:after {

				}
			}
		}
	}
}

// Switch active style
input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px transparentize($switch-bg-color, .9);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .08);
}

// Disabled Styles
.input-switch input[type=checkbox][disabled] + .lever {
	cursor: default;
}

.input-switch label input[type=checkbox][disabled] + .lever:after,
.input-switch label input[type=checkbox][disabled]:checked + .lever:after {
	background-color: $input-disabled-solid-color;
}
