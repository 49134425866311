html {
	box-sizing: border-box;
	font-size: $font-size;
	line-height: $line-height;
	color: $text-color;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}

body {
	background: #eeeeee;
	height: 100%;
}

*, *:before, *:after {
	box-sizing: border-box;
}

dl {
	margin: 0;
	dt {
		margin-bottom: 0;
		font-size: 75%;
		color: #aaa;
		font-weight: normal;
	}

	dd {
		margin: 0;
		//margin-bottom: 12px;
	}

	&.inline {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		width: 100%;
		overflow: visible;

		dt {
			font-size: 100%;
			flex: 0 0 35%;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		dd {
			flex: 0 0 65%;
			margin-left: auto;
			text-align: left;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}


a {
	color: $link-color;
	text-decoration: none;
	transition: color 0.5s;

	&:hover {
		color: color("blue", "lighten-2");
	}

	&.inverse {
		color: color( 'blue', 'lighten-4');
	}
}

img {
	vertical-align: middle;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}


.text-error, .text-danger {
	color: $error-color;
}
.text-success {
	color: $success-color;
}
.text-warning {
	color: $warning-color;
}

.text-bold {
	font-weight : bold;
}
.text-italic {
	font-style : italic;
}
.text-strike {
	text-decoration: line-through;
}

.text-xxlarge {
	font-size: 1.9rem;
}

.text-xlarge {
	font-size: 1.6rem;
}

.text-large {
	font-size: 1.3rem;
}

.text-small {
	font-size: 0.8rem;
}

.text-xsmall {
	font-size: 0.6rem;
}

.text-blur {
	color: transparent;
	text-shadow: rgba(0, 0, 0, 0.9) 0 0 15px;
	font-size: 100%;
}

// Positioning
.valign-wrapper {
	display        : flex !important;
	flex-direction : row !important;
	align-items    : center !important;

	.valign {
		display : block;
	}
}

.section {
	padding-top    : 1rem;
	padding-bottom : 1rem;
}

hr, .divider {
	height           : 1px;
	overflow         : hidden;
	background-color : #e0e0e0;
	margin           : 16px 0;
	border           : none;
}

.circle, .rounded {
	border-radius : 50%;
	vertical-align: middle;
}


// Hide visually and from screen readers
.hide, .hidden {
	display: none !important;
}


// classic clearfix
.left   { float: left !important; }
.right  { float: right !important; }
.clearfix {
	clear : both;
}

.no-wrap {
	white-space : nowrap;
}

// Pre
pre[class*="language-"] {
	padding : 7px 12px;
	border  : solid 1px rgba(51, 51, 51, 0.12);
}

code {
	border : none;
}

// Z-levels
.z-depth-0 {
	box-shadow : none !important;
}

.z-depth-1 {
	box-shadow : 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth-1-half {
	box-shadow : 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.z-depth-2 {
	box-shadow : 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-3 {
	box-shadow : 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}

.z-depth-4 {
	box-shadow : 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}

.z-depth-5 {
	box-shadow : 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

/**
 * Tronque les mots trop longs
 */

.truncate {
	white-space: nowrap;
	overflow: hidden;
	position: relative;

	.paragraph-end {
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
		background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, StartColorStr='#00ffffff', EndColorStr='#ffffff');
		bottom: 0;
		height: 100%;
		position: absolute;
		right: 0;
		width: 40px;
	}
}


/**
 * Beautifull, mini scrollbar
 */

.mini-scroll {
	@include mini-scrollbar;
}

// Définition des m[b,t,l,r]-x et p[b,t,l,r]-x
// loops through array:
// vars: amt, direction, class-suffix
$left-space-vars : (0 left l, 5 left l, 10 left l, 15 left l, 20 left l, 25 left l, 30 left l);
$right-space-vars : (0 right r, 5 right r, 10 right r, 15 right r, 20 right r, 25 right r, 30 right r);
$bottom-space-vars : (0 bottom b, 5 bottom b, 10 bottom b, 15 bottom b, 20 bottom b, 25 bottom b, 30 bottom b);
$top-space-vars : (0 top t, 5 top t, 10 top t, 15 top t, 20 top t, 25 top t, 30 top t);

@mixin generate-spacing-classes(
	$default-space-amounts-with-direction: (0 left l, 5 left l, 10 left l, 15 left l,20 left l, 25 left l, 30 left l)
) {
	@each $space in $default-space-amounts-with-direction {
		.m#{nth($space, 3)}-#{nth($space, 1)} {
			margin-#{nth($space, 2)}: #{nth($space, 1)}px !important;
		}

		.p#{nth($space, 3)}-#{nth($space, 1)} {
			padding-#{nth($space, 2)}: #{nth($space, 1)}px !important;
		}
	}
}

@include generate-spacing-classes($left-space-vars); // left comes by default
@include generate-spacing-classes($right-space-vars);
@include generate-spacing-classes($bottom-space-vars);
@include generate-spacing-classes($top-space-vars);

/*********************
  Media Query Classes
**********************/
.hide-on-xsmall-only {
	@media #{$xsmall-only} {
		display: none !important;
	}
}

.hide-on-small-and-down {
	@media #{$small-and-down} {
		display: none !important;
	}
}
.hide-on-med-and-down {
	@media #{$medium-and-down} {
		display: none !important;
	}
}
.hide-on-large-and-down {
	@media #{$large-and-down} {
		display: none !important;
	}
}
.hide-on-xlarge-and-down {
	@media #{$extra-large-and-down} {
		display: none !important;
	}
}

.hide-on-small-and-up {
	@media #{$small-and-up} {
		display: none !important;
	}
}
.hide-on-med-and-up {
	@media #{$medium-and-up} {
		display: none !important;
	}
}
.hide-on-large-and-up {
	@media #{$large-and-up} {
		display: none !important;
	}
}

.hide-on-xlarge-and-up {
	@media #{$extra-large-and-up} {
		display: none !important;
	}
}

.hide-on-small-only {
	@media only screen and (min-width: $xsmall-screen) and (max-width: $small-screen) {
		display: none !important;
	}
}
.hide-on-med-only {
	@media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
		display: none !important;
	}
}
.hide-on-large-only {
	@media only screen and (min-width: $medium-screen) and (max-width: $large-screen) {
		display: none !important;
	}
}
.hide-on-xlarge-only {
	@media only screen and (min-width: $large-screen) and (max-width: $xlarge-screen) {
		display: none !important;
	}
}
.hide-on-xxlarge-only {
	@media #{$double-extra-large-and-up} {
		display: none !important;
	}
}


.show-on-xsmall-only {
	@media #{$xsmall-only} {
		display: block !important;
	}
}

.show-on-small-and-down {
	@media #{$small-and-down} {
		display: block !important;
	}
}
.show-on-med-and-down {
	@media #{$medium-and-down} {
		display: block !important;
	}
}
.show-on-large-and-down {
	@media #{$large-and-down} {
		display: block !important;
	}
}
.show-on-xlarge-and-down {
	@media #{$extra-large-and-down} {
		display: block !important;
	}
}

.show-on-small-and-up {
	@media #{$small-and-up} {
		display: block !important;
	}
}
.show-on-med-and-up {
	@media #{$medium-and-up} {
		display: block !important;
	}
}
.show-on-large-and-up {
	@media #{$large-and-up} {
		display: block !important;
	}
}

.show-on-xlarge-and-up {
	@media #{$extra-large-and-up} {
		display: block !important;
	}
}

.show-on-small-only {
	@media only screen and (min-width: $xsmall-screen) and (max-width: $small-screen) {
		display: block !important;
	}
}
.show-on-med-only {
	@media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
		display: block !important;
	}
}
.show-on-large-only {
	@media only screen and (min-width: $medium-screen) and (max-width: $large-screen) {
		display: block !important;
	}
}
.show-on-xlarge-only {
	@media only screen and (min-width: $large-screen) and (max-width: $xlarge-screen) {
		display: block !important;
	}
}
.show-on-xxlarge-only {
	@media #{$double-extra-large-and-up} {
		display: block !important;
	}
}




// Couleurs des univers
.bg-youtubers {
	background-color: $youtubers-color !important;
}
.bg-youtubers-gradient {
	background-image: linear-gradient(#c62828, #ef5350) !important;
}

.bg-brands {
	background-color: $brands-color !important;
}
.bg-brands-gradient {
	background-image: linear-gradient( #2277BA, #74BFF6) !important;
}

.bg-networks {
	background-color: $networks-color !important;
}
.bg-networks-gradient {
	background-image: linear-gradient(#ef6c00, #ffb74d) !important;
}

// Couleur des réseaux sociaux
.bg-creator {
	background-color: $creator-color !important;
}
.text-creator {
	color: $creator-color;
}

.bg-youtube {
	background-color: $youtube-color !important;
}
.text-youtube {
	color: $youtube-color;
}

.bg-gplus {
	background-color: $gplus-color !important;
}
.text-gplus {
	color: $gplus-color;
}

.bg-facebook {
	background-color: $facebook-color !important;
}
.text-facebook {
	color: $facebook-color;
}

.bg-twitter {
	background-color: $twitter-color !important;
}
.text-twitter {
	color: $twitter-color;
}

.bg-instagram {
	background-color: $instagram-color !important;
}
.text-instagram {
	color: $instagram-color;
}

.bg-twitch {
	background-color: $twitch-color !important;
}
.text-twitch {
	color: $twitch-color;
}
