/* Text Inputs + Textarea
   ========================================================================== */

/* Style Placeholders */

::-webkit-input-placeholder {
	color : $placeholder-text-color;
}

:-moz-placeholder { /* Firefox 18- */
	color : $placeholder-text-color;
}

::-moz-placeholder { /* Firefox 19+ */
	color : $placeholder-text-color;
}

:-ms-input-placeholder {
	color : $placeholder-text-color;
}

.input-field {
	padding: 25px 0 10px 0;

	&.input-text, &.input-email, &.input-url, &.input-password, &.input-textarea, &.input-number {
		label {
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 0.8rem;
			top:	0;

			&.active {
				font-size: 0.8rem;
				transform: none;
			}
		}
	}

	&.input-checkbox {
		padding: 28px 0 7px 0;
	}

	&.input-select {
		label {
			// fix WT-2050 Nom des zones de saisie dépasse (Label passant au dessus du champ en V flat)
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			//	font-size: 1rem;
			top: 0;
		}

		.select-wrapper {
			background-color: white;
			padding: 0;

			.dropdown-content {
				&.multiple-select-dropdown {
					label {
						//padding-left: 16px !important;
						overflow: inherit;
						width: auto;
						white-space: normal;
						padding: 0;
						margin: 0 28px 0 2px;
						top: 4px !important;
						position: relative;
						height: 20px;
					}
				}
			}


			span.caret {
				top: -2px;
				right: 9px;
				color: rgba(0,0,0,0.75);
			}
		}

		input.select-dropdown {
			background-color: transparent;
		}
	}

	/* Text inputs */
	input:not([type]),
	input[type=text],
	input[type=password],
	input[type=email],
	input[type=url],
	input[type=time],
	input[type=date],
	input[type=datetime],
	input[type=datetime-local],
	input[type=tel],
	input[type=number],
	input[type=search],
	textarea {
		// General Styles
		background-color   : white;
		border             : 1px solid color('grey', 'lighten-2');
		border-radius      : 2px;
		line-height        : 26px;

		box-sizing         : border-box;
		color              : $text-color;
		box-shadow         : none;
		margin             :	0;
		padding            : 4px;

		// Disabled input style
		&:disabled,
		&[readonly="readonly"] {
			border:1px solid color('grey', 'lighten-2');
			background: color('grey', 'lighten-4');
		}

		// Disabled label style
		&:disabled + label,
		&[readonly="readonly"] + label {
			color : $input-disabled-color;
		}

		// Focused input style
		&:focus:not([readonly]) {
			border-bottom : 1px solid $input-focus-color;
			box-shadow    : 0 1px 0 0 $input-focus-color;
		}
	}
}
