
form.filters .dropdown {
	line-height: 35px;
}

.active-filters {
	display: flex;
	padding-top: 6px;
	.filters {
		flex-grow: 1;
	}
}

.card {
    position: initial;
}

.deals {
	display: grid;
	grid-template-columns: repeat(auto-fill, 380px);
	grid-gap: 6px;
	justify-content: space-between;
}

.deal {
	.card-title {
		display: flex;
        
		.logo img {
            width: 60px;
			height: 100%;
			margin-right: 10px;
		}
        
		.infos {
            flex-grow: 1;
            align-items: center;
			display: flex;

			.header {
				flex-grow: 1;
				display: flex;
                flex-direction: column;

				.title {
					font-weight: bold;
					flex-grow: 1;
                    font-size: 1em;
				}

				.expected {
					width: 120px;
					font-size: 12px;
					color: rgba(0, 0, 0, 0.54);
				}
			}

			.status-badge {
                align-items: center;
                border-radius: 5px;
                display: flex;
                font-size: 0.7em;
                padding: 4px 8px;
                margin: 0px 12px;

                .icon {
                    margin-right: 5px;
                }
			}
		}
	}

	.card-image {
		height: 60%;
		background-size: cover;
		background-position: center;
		position: relative;

		.subtypes {
			position: absolute;
			bottom: 0;
			left: 0;

			.subtype {
				font-size: 14px;
				padding: 5px;
				color: #ffffff;
			}
		}

		.pricing {
			color: #ffffff;
			position: absolute;
			bottom: 0;
			right: 0;
			padding: 2px 4px;
			font-weight: bold;
			text-align: right;
			line-height: 16px;

			&.danger {
				background-color: #d32f2f;
			}
			&.warning {
				background-color: #f57c00;
			}
			&.success {
				background-color: #388e3c;
			}

			.wizdeo_commission {
				margin-top: 2px;
				font-weight: normal;
				color: #fff;
				font-size: 12px;
				line-height: 13px;
			}
		}


		label {
			font-size: 10px;
			color: rgba(255,255,255,0.5);
			font-style: italic;
			font-weight: normal;
			display: block;
		}
	}

	.card-content {
		overflow-y: auto;
		overflow-wrap: break-word;
		height:220px;
		font-size: 15px;
	}
}

.thread{

	.card-content {
		display: flex;
		flex-direction: column-reverse;
	}


	.input-textarea {
		padding: 6px 0 !important;
	}
	textarea {
		line-height: normal !important;
	}

	.notification {
		text-align: center;
	}

	.message {
		margin: 9px 0;

		.header {
			font-size: 11px;
		}
	}

	.notification{
		.message {
			margin: 16px 0;

			.text {
				color: grey;
				font-style: italic;
			}
		}
	}

	.talent{
		text-align: right;

		.message {
			text-align: right;

			.text {
				background: #BBDEFB;
				border-radius: 10px;
				padding: 9px;
				display: inline-block;
			}
		}
	}

	.admin{
		.message {
			.text {
				background: #FEE0B2;
				border-radius: 10px;
				padding: 9px;
				display: inline-block;
			}
		}
	}

}

@media (max-width:424px) {
	.deals{
		grid-template-columns: repeat(auto-fill,100%);
	}
}
