$card-border-radius: 3px !default;
.card {
	position: relative;
	margin: 0 0 16px 0;
	transition: box-shadow .25s;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	border-radius: $card-border-radius;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	header, .card-header {
		background: $header-bg-color;
		color: white;
		padding: 12px 16px;
		position: relative;

		.inline-actions {
			position : absolute;
			top      : 24px;
			right    : 24px;
		}
	}

	.card-content {
		padding: 12px 16px;
		position: relative;
	}

	.card-actions {
		position: relative;
		background-color: inherit;
		border-top: 1px solid rgba(160, 160, 160, 0.2);
		padding: 6px 12px 4px;
		text-align: right;
	}

	.card-list {
		overflow-y: auto;
		overflow-x: hidden;
		border-bottom: 1px solid rgba(160, 160, 160, 0.2);
		.card-list-item {
			padding: 8px 4px;
			border-top: 1px solid rgba(160, 160, 160, 0.2);
			display: flex;
			flex-direction: row;
			align-items: center;
			.title {
				padding: 0 4px;
				overflow: hidden;
				flex-grow: 1;
			}
		}
	}

	&.success {
		background-color: $success-color;
		color: white;
		.progress-bar-widget::-webkit-progress-bar, progress[value]::-webkit-progress-bar {
			background-color: lighten($success_color,10%);
		}
		.progress-bar-widget::-webkit-progress-value, progress[value]::-webkit-progress-value {
			background-color: darken($success_color,10%);
		}
	}

	&.warning {
		background-color: $warning-color;
		color: white;
		.progress-bar-widget::-webkit-progress-bar, progress[value]::-webkit-progress-bar {
			background-color: lighten($warning_color,10%);
		}
		.progress-bar-widget::-webkit-progress-value, progress[value]::-webkit-progress-value {
			background-color: darken($warning_color,10%);
		}
	}

	&.danger, &.error {
		background-color: $error-color;
		color: white;

		.progress-bar-widget::-webkit-progress-bar, progress[value]::-webkit-progress-bar {
			background-color: lighten($error_color,10%);
		}
		.progress-bar-widget::-webkit-progress-value, progress[value]::-webkit-progress-value {
			background-color: darken($error_color,10%);
		}
	}

	&.info {
		background-color: $info-color ;
		color: white;
		.progress-bar-widget::-webkit-progress-bar, progress[value]::-webkit-progress-bar {
			background-color: lighten($info_color,10%);
		}
		.progress-bar-widget::-webkit-progress-value, progress[value]::-webkit-progress-value {
			background-color: darken($info_color,10%);
		}
	}

	&.card-graph {
		.card-content {
			flex-grow: 1;
		}
	}
}

.card-panel {
	position: relative;
	transition: box-shadow .25s;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	margin: 0 0 16px 0;
	border-radius: $card-border-radius;
	background-color: #ffffff;
}

.card-panel, .card {
	.card-menu {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 900;
	}
}

.card-panel, .card-content {
	h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
		margin-top: 0;
	}

	.row:last-child {
		margin-bottom: 0;
	}
}
