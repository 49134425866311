.container {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5px 20px;
    background-color: transparent;

    &:hover {
        background-color: #00000026;
        .label, .icon {
            color: white;
        }

        .options_container {
            transform: scale(1);
        }
    }

    .label, .icon {
        font-size: 16px;
        color: #cacaca
    }

    .icon {
        margin-left: 10px;
    }
    
    .options_container {
        transition: all 0.3s ease;
        transform: scale(0);
        transform-origin: 0 0;
        background-color: white;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        z-index: 1;

        .option {
            padding: 12px;
            font-size: 16px;
            color: #000000b5;
            background-color: white;
        
            &:hover {
                background-color: #e0e0e0;
            }
        }
    }
}