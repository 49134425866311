$selectize-arrow-size : 5px;
$selectize-arrow-color : rgba(0, 0, 0, 0.75);

.topics-kg {
	.kge-search-picker {
		width: 100%
	}

	.input-field {

		&:after {
			clear        : left;
			content      : ' ';
			display      : block;
			position     : absolute;
			right        : 10px;
			top          : 50%;
			left         : initial;
			margin-top   : round((-1 * $selectize-arrow-size / 2));
			width        : 0;
			height       : 0;
			border-style : solid;
			border-width : $selectize-arrow-size $selectize-arrow-size 0 $selectize-arrow-size;
			border-color : $selectize-arrow-color transparent transparent transparent;
		}

		input[type=text].topics-search {
			cursor : pointer;
		}
	}

	.multi-topics, .search-field {
		display: none;
	}

	.chosen-container-multi .chosen-choices li {
		height        : auto;
		line-height   : 13px;
		margin        : 0 5px 0 0;
		padding-right : 16px;
		border-radius : 3px;
		background    : #2196f3;
		color         : white;
		font-weight   : normal;
		font-size     : 13px;

		& > span {
			color         : white;
			margin        : 2px;
			padding       : 2px 4px;
			font-weight   : normal;
			font-size     : 13px;
			height        : auto;
			line-height   : 13px;
		}

		.search-choice-close:before {
			color: white;
			line-height: 20px;
			width: 16px;
			padding-right: 2px;
			font-size: 10px;
			font-weight: normal;
		}
		.search-choice-close:hover:before {
			background: rgba(0, 0, 0, 0.05) !important;
		}
	}
}

