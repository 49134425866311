$navbar-height: 60px;
$badge-font-weight: normal;

$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$primary: #1976D2;
$light-text: #FAFAFA;
$dark-background: #303646;
$light-background: #EEEEEE;

// Sidebar colors
$sidebar-bg-color: #FAFAFA;
$active-bg-color: #1976D21A;
$hover-bg-color: #DEDEDE;
$sidebar-color: #3A3A3A;
$highlight-color: #3A3A3A;
$submenu-bg-color: #F0F0F0;
$submenu-color: #3A3A3A;
$icon-bg-color: #EEEEEE;