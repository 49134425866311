@import './_variables';
@import './deals';
@import './framework/materialize';
@import '../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
@import '../../node_modules/react-tabs/style/react-tabs';

body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;

    .app {
        flex-grow: 1;
        display: flex;
        position: relative;
        overflow: auto;

        main {
            background-color: $light-background;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: auto;
        }
    }
}

.task-card {
    .card-content {
        margin: 12px 18px;

        .row {
            .col {
                margin-left: 0;
            }
        }
    }
}

.react-tabs__tab-list {
    background-color: $primary;
}

.react-tabs__tab {
    border: 0px;
    font-size: 0.9em;
    color: $light-text;
    padding: 16px 18px 13px;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        padding-bottom: 16px;
    }
}

.react-tabs__tab--selected {
    border: 0px;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid white;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        padding-bottom: 13px;
    }
}

.react-tabs__tab--disabled {
    color: lightgrey;
    &:hover {
        background: transparent;
    }
}

.modal-header {
    color: white;
}

.modal-content {
    padding: 15px;
}

.task-input {
    font: 400 16px apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

.container-large {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.table-header {
    justify-content: space-between;
}
.revenues-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.modal-input {
    display: flex;
    flex-direction: column;

    label {
        font-size: 14px;
        margin-bottom: 5px;
    }

    textarea {
        border: 3px solid #e0e0e0;
        border-radius: 2px;
        padding: 4px;
    }

    textarea:focus {
        outline: none;
        border-bottom: 3px solid $primary;
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin: 15px;
}

.rbc-toolbar button:hover {
    color: currentColor;
    background-color: transparent;
    border-color: transparent;
}

.custom-btn {
    display: inline-flex;

    .icon {
        align-self: center;
        font-size: 14px;
    }
}

.subtype {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
        font-size: 1.5em;
    }
}

.list-subtype {
    padding: 5px;
    font-size: 2.2em;
}

.empty-list-message {
    padding: 20px;
    text-align: center;
}

.deal-page {
    overflow: auto;
    flex-grow: 1;

    .container-large {
        height: 100%;

        .row {
            height: 100%;

            .col {
                height: 100%;
            }
        }

        .task-row {
            height: auto;
        }
    }
}

.deal-page {
    overflow: auto;
    flex-grow: 1;

    .row {
        height: 100%;
    }
}

.deal-card {
    height: 100%;
    position: unset;
    margin-bottom: 20px;

    .card-content {
        height: 100%;
    }
}

.thread {
    height: 100%;
}

.bc-card-icon {
    position: absolute;
    top: -25px;
    z-index: 0;
    left: -25px;
    font-size: 8em;
}

.thread-image {
    height: 60px;
    object-fit: cover;
    aspect-ratio: 1;
}

.card-header {
    display: flex;
    align-items: center;
}

.card-header-icon {
    margin-right: 12px;
}

.sidebar-header {
    padding: 12px;
    display: flex;
    align-items: center;

    .infos {
        .fullname {
            font-weight: bold;
            font-size: 1em;
        }

        .email {
            font-size: 0.7em;
        }
    }

    .logo {
        border-radius: 100%;
        height: 60px;
        aspect-ratio: 1;
    }
}

.clickable {
    cursor: pointer;
}

.channel-card {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    margin: 0px;
}

.channel-header {
    background-position: center;
    background-size: cover;
    height: 100px;
    display: flex;
    align-items: flex-end;
    padding: 0px 10px;
}

.channel-infos {
    display: flex;
    align-items: center;
}

.channel-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.channel-kpis {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.filters-title {
    color: #bdbdbd;
    font-size: 13px;
    font-style: italic;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 15px;
}

.search-input {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    line-height: 26px;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 3px 6px;
}

.search-input:focus {
    outline: none;
    border: 1px solid $primary;
}

.search-button {
    box-shadow: none;
}

.react-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.react-tabs__tab-panel {
    flex-grow: 1;
}

.tab-list {
    background-color: $primary;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.tab-item {
    padding: 10px 20px;
    border-radius: 0px;
    color: $light-text;

    :hover {
        cursor: pointer;
    }
}

.cs-main-container {
    height: 100%;
}

.login {
    height: 100%;
    background-color: $light-background;
    display: flex;
    align-items: center;
    padding: 20px;

    .card {
        margin: 0 auto;
    }

    .card-header {
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        background-color: $dark-background;
    }

    .card-content {
        padding: 50px 50px;
    }

    .card-footer {
        padding: 10px;
        display: flex;
        justify-content: center;
    }

    .version {
        font-size: 12px;
    }
}

.MuiCardHeader-root
.MuiCardHeader-content {
    overflow: hidden;
    flex: 1;
}

.deals-list {
    display: flex;
}

.deals-list-item {
    td {
        padding: 12px 18px;
    }
}

.deal-cards {
    display: none;
}

.chat-avatar {
    display: flex;
}

.thread {
    height: 100%;
}

td {
    white-space: pre-wrap;
}

.input-field {
    &.warning {
        &:after {
            color: orange;
        }
    }
}

@media (max-width: $breakpoint-md) {
    .chat-avatar {
        display: none;
    }

    .cs-message__avatar {
        display: none;
    }

    .deal-cards {
        display: flex;
        flex-direction: column;
    }
}

.MuiCardHeader-title {
	font-weight: bold;
}
