@import '../../../styles/_variables.scss';
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
    .pro-sidebar-layout {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #bdbdbd;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid #eeeeee;
            background-color: #bdbdbd;
        }

        &::-webkit-scrollbar-track {
            background-color: #eeeeee;
        }
    }

    .pro-menu {
        padding-top: 0px;
        padding-bottom: 0px;

        .section_title {
            font-size: 14px;
            font-weight: 500;
            color: #a8a8a8;
            margin: 10px 0;
            padding: 10px;
            text-transform: uppercase;
        }

        > ul {
            > .pro-sub-menu {
                > .pro-inner-list-item {
                    > div > ul {
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
                }
            }
        }
        .pro-menu-item {
            .pro-inner-item {
                .pro-item-content {
                    white-space: normal;
                }
            }
            &.pro-sub-menu {
                .pro-inner-list-item {
                    padding-left: 0px;

                    .pro-menu-item {
                        .pro-inner-item {
                            padding: 10px 30px;

                            &::before {
                                list-style: none;
                                content: none;
                            }
                        }

                        &.active {
                            .pro-inner-item {
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }

            &.active {
                border-left: 5px solid $primary;
                background: $active-bg-color;

                .pro-inner-item {
                    padding-left: 15px;
                }
            }

            > .pro-inner-item {
                font-size: 16px;

                &:hover {
                    background: $hover-bg-color;
                    .pro-icon-wrapper {
                        .pro-icon {
                            animation: none;
                        }
                    }
                }

                .pro-icon-wrapper {
                    font-size: 18px;
                }
            }
        }
    }
}

.lang_select {
    display: none;
}

@media (max-width: $breakpoint-md) {
    .lang_select {
        display: block;
    }

    .pro-sidebar {
        top: 0;
    }
}
