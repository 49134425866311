@import '../../../styles/variables.scss';

ul .nav {
    height: 100%;
}

.navbar {
    background-color: $dark-background;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.icon {
    color: $light-text;
}

.logo {
    cursor: pointer;
    padding: 8px;
    height: 90%;
}

.lang_select {
    display: block;
    height: 100%;
}

.btn_toggle {
    display: none;
    padding: 8px;
}

@media (max-width: $breakpoint-md) {
    .btn_toggle {
        display: flex;
    }

    .lang_select {
        display: none;
    }
}
