.table-filters .filters-container {
	.title {
		margin-bottom: 6px;
	}

	.actions {
		text-align: right;
		margin-top: 9px;
	}
}
