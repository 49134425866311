.filter-tools {
	form {
		position: relative;
		margin-top: 12px;

		select {
			background-color: rgba(255, 255, 255, 0.9);
			width: 100%;
			padding: 5px;
			border: 1px solid #f2f2f2;
			border-radius: 2px;
			height: 3rem;
			display: none;
		}

		label {
			position: absolute;
			top: -14px;
			font-size: 0.7rem;
			color: rgba(0, 0, 0, 0.5);
		}

		.select-wrapper {
			position: relative;
			font-size: 12px;
			line-height: 14px;
			/*font-family: "Roboto", sans-serif;*/
			font-weight: normal;
			color: rgba(0, 0, 0, 0.87);

			span.caret {
				color: initial;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				height: 10px;
				margin: auto 0;
				font-size: 10px;
				line-height: 10px;
				font-family: "Roboto", sans-serif;
				font-weight: normal;
			}

			input.select-dropdown {
				position: relative;
				cursor: pointer;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
				outline: none;
				width: 100%;
				padding: 0;
				display: block;
				box-shadow: none;
				box-sizing: content-box;
				transition: all 0.3s;
				height: 1.6rem;

				&:not([disabled]):focus {
					border-bottom-color: RGB(3, 155, 229);
				}
			}

			.dropdown-content {
				background-color: #fff;
				margin: 0;
				display: none;
				min-width: 100px;
				max-height: 650px;
				overflow-y: auto;
				opacity: 0;
				position: absolute;
				z-index: 9999;
				will-change: width, height;
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

				li {
					clear: both;
					color: rgba(0, 0, 0, 0.87);
					cursor: pointer;
					min-height: 50px;
					line-height: 1.5rem;
					width: 100%;
					text-align: left;
					text-transform: none;

					&:hover, &.active, &.selected {
						background-color: #eee;
					}

					& > a, & > span {
						font-size: 16px;
						color: rgba(0, 0, 0, 0.54);
						display: block;
						line-height: 22px;
						padding: 14px 16px 14px 14px;
					}

					&.disabled, &.disabled > span {
						color: rgba(0, 0, 0, 0.3);
						background-color: transparent;
					}

					&.selected > a, &.selected > span {
						color: #039be5;
					}
				}
			}

			ul li {
				list-style-type: none;
			}

			ul li > span:before {
				color: rgba(0, 0, 0, 0.54);
				display: inline-block;
				width: 16px;
				margin-right: 8px;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				content: " ";
			}

			ul li.selected > span:before {
				font: normal normal normal 14px/1 FontAwesome;
				content: "\f00c";
			}
		}
	}
}