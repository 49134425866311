@import '@chatscope/chat-ui-kit-styles/dist/default/styles';

.cs-message__content-wrapper {
    margin-bottom: 8px;
}

.cs-message--outgoing .cs-message__sender-name,
.cs-message--outgoing .cs-message__sent-time,
.cs-message--incoming .cs-message__sender-name,
.cs-message--incoming .cs-message__sent-time {
    display: flex;
    height: 22px;
}

.cs-message--outgoing {
    .message-header {
        flex-direction: row-reverse;
    }
    .cs-message__content {
        background: #bbdefb;
        text-align: right;
        border-radius: 0.7em;
    }
}

.cs-message--incoming {
    .message-footer {
        .cs-message__sent-time {
            margin: 0;
            padding-left: 0;
        }
    }
    .cs-message__content {
        background: #ffe0b2;
        border-radius: 0.7em;
    }
}

.small_loader {
    .cs-loader {
        align-items: center;
        justify-content: center;
        &::before {
            display: none;
        }
        &::after {
            position: initial;
            width: 18px;
            height: 18px;
            border-width: 3px;
        }
    }
}

.cs-message__header .message-header {
    flex-direction: row-reverse;
}

.cs-message-separator {
    color: grey;
    font-size: 12px;

    &::before,
    &::after {
        background-color: transparent;
    }
}

.ps__thumb-y {
    background-color: #e0e0e0;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #e0e0e0;
}

.message-header {
    font-weight: bold;
}

.cs-button {
    color: #1565c0;
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container {
    background-color: transparent;
}

.cs-chat-container
    .cs-message-input
    .cs-message-input__content-editor-wrapper:first-child {
    margin-left: 0px;
}

.message-footer {
    font-size: 12;
}

.cs-message-list__scroll-wrapper {
    padding: 12px;
}

.cs-message-list__loading-more {
    position: static;
    background-color: transparent;
    padding: 12px;
}

.cs-message__custom-content {
    white-space: normal;
}
