@charset "utf-8";

/**
 * Définitions des styles d'animation avec @keyframe, qui ne peuvent être créé à l'intérieur de .materilize
 *
 */

/* Mixins permettant d'utiliser animation et keyfram sur tous les navigateurs */
@mixin animation($animate...) {
	$max: length($animate);
	$animations: '';

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	-webkit-animation: $animations;
	-moz-animation:    $animations;
	-o-animation:      $animations;
	animation:         $animations;
}

@mixin transition($property: all, $duration: .3s, $ease: linear){
	-webkit-transition: $property $duration $ease;
	-moz-transition: $property $duration $ease;
	-o-transition: $property $duration $ease;
	transition: $property $duration $ease;
}

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}

/** Animation du loader progress circular **/

@include keyframes(progress-rotate) {
	100% { transform: rotate(360deg); }
}
@include keyframes(progress-dash) {
	0%{
		stroke-dasharray: 1,200;
		stroke-dashoffset: 0;
	}
	50%{
		stroke-dasharray: 89,200;
		stroke-dashoffset: -35px;
	}
	100%{
		stroke-dasharray: 89,200;
		stroke-dashoffset: -124px;
	}
}
@include keyframes(progress-color) {
	100%, 0%{
		stroke: #d62d20;
	}
	40%{
		stroke: #0057e7;
	}
	66%{
		stroke: #008744;
	}
	80%, 90%{
		stroke: #ffa700;
	}
}
