@import '../../../styles/_variables.scss';

.container {
    height: 100%;
    margin-bottom: 0px;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px !important;

    .icon {
        margin-right: 15px;
    }

    .title {
        font-size: 18px;
        font-weight: 500;
    }
}

.content {
    background: #ffffff;
    display: grid;
    flex-direction: column;
    height: 100%;
}
