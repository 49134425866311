$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;

.chip, .chip-small {
	display: inline-block;
	height: 32px;
	font-size: 14px;
	font-weight: 500;
	color: rgba(0, 0, 0, .6);
	line-height: 32px;
	padding: 0 12px;
	border-radius: 16px;
	background-color: $chip-bg-color;
	margin-bottom: 8px;
	margin-right: 5px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);

	img {
		float: left;
		margin: 1px 8px 0 -10px;
		height: 30px;
		width: 30px;
		border-radius: 50%;
	}

	.close {
		cursor: pointer;
		float: right;
		font-size: 12px;
		line-height: 32px;
		padding-left: 8px;
		color: color('grey', 'lighten-2');
		&:hover {
			color: white;
		}
	}

	&.selected {
		background-color: color('blue', 'darken-1');
		color: white;
	}

	&.selectize {
		border-radius: 3px;
		background: #2196f3;
		color: white;
		margin: 0 4px 2px 0;
		padding: 4px;
		line-height: 15px;
		height: auto;
	}
}

.chip-small {
	height: 22px;
	font-size: 11px;
	line-height: 22px;


	.close {
		font-size: 11px;
		line-height: 22px;
		padding-left: 8px;
	}

	img {
		height: 20px;
		width: 20px;
	}
}

.chips {
	border: none;
	border-bottom: 1px solid $chip-border-color;
	box-shadow: none;
	margin: $input-margin;
	min-height: 45px;
	outline: none;
	transition: all .3s;

	&.focus {
		border-bottom: 1px solid $chip-selected-color;
		box-shadow: 0 1px 0 0 $chip-selected-color;
	}

	&:hover {
		cursor: text;
	}

	.input {
		background: none;
		border: 0;
		color: rgba(0, 0, 0, .6);
		display: inline-block;
		font-size: $input-font-size;
		height: $input-height;
		line-height: 32px;
		outline: 0;
		margin: 0;
		padding: 0 !important;
		width: 120px !important;
	}

	.input:focus {
		border: 0 !important;
		box-shadow: none !important;
	}
}

// Form prefix
.prefix ~ .chips {
	margin-left: 3rem;
	width: 92%;
	width: calc(100% - 3rem);
}

.chips:empty ~ label {
	font-size: 0.8rem;
	transform: translateY(-140%);
}
