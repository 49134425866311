table {
	table-layout : auto;
	background   : none;
	margin       : 0;
	width: 100%!important;
	overflow-x: scroll;
	border-collapse: collapse;
	border-spacing: 0;

	th {
		background     : color( 'grey', 'lighten-2' );
		font-size: 0.875rem;
		font-weight: normal;
		padding        : 9px 9px;
		text-align     : left;
		vertical-align : middle;
		border: none;

		.paginator {
			display: flex;
			align-items: center;
			vertical-align: middle;
			.link {
				flex-grow: 1;
			}
			.dir {
				margin-left: 6px;
				text-align: right;
			}
		}

		a {
			line-height    : 16px;
			&:not(.btn):hover {
				border-bottom: 1px solid;
			}
		}

		.evolution {
			font-size : 11px;
			white-space: nowrap;

			&:not(a) {
				color : inherit;
			}
		}
	}

	&.no-stripe {
		tr {
			border-bottom: 1px solid rgba(160, 160, 160, 0.2);

			&:nth-of-type(odd) {
				background: transparent;
			}
		}
	}

	&:not(.no-hover) {
		tr:hover {
			background : color( 'grey', 'lighten-2');
		}
	}

	tr {
		transition : background 0.3s;
		border-bottom: 1px solid transparent;
		&[data-href] {
			cursor: pointer;
		}

		&:nth-of-type(odd) {
			background : color( 'grey', 'lighten-4');
		}

		&:not(.table-row-selected):last-of-type {
			border-bottom : none;
		}

		&.selected {
			background:color('blue', 'lighten-4');
			border-bottom: 1px solid color('blue','lighten-3');

			&:hover {
				background:color('blue', 'lighten-3');
			}
		}
		&.disabled{
			transition : none;
			background : #e6e6e6;
			> td {
				color: #bbb7b7;
				> a {
					color: #9dcff7;
				}
				.channel .detail .title a{
					color: #bbb7b7;
				}
			}
			&:hover{
				background : #e6e6e6;
			}
		}
	}

	td {
		padding    : 6px 9px;
		text-align : left;
		font-size: 0.875rem;

		&[data-href] {
			cursor: pointer;
		}

		&.title, .title {
			font-size      : 12px;
			font-weight    : bold;
			text-transform : uppercase;
			line-height: normal;
		}
		.desc {
			font-size : 11px;
			color     : rgba(0, 0, 0, 0.65);
		}

		// Date/Time cells
		.day {
			font-size      : 11px;
			text-transform: capitalize;
			line-height: normal;
		}
		.date {
			font-size      : 13px;
			font-weight    : bold;
			white-space    : nowrap;
			line-height: normal;
		}
		.time {
			font-size : 11px;
			line-height: normal;
		}

		// DL
		dl {
			dt {
				float        : left;
				margin-right : 6px;
			}
			dd {
				overflow: hidden;
			}
		}

		// Channel/Video cells, common properties
		.channel, .video {
			display:flex;

			.thumb {
				width: 44px;
				margin-right:6px;
				flex-shrink: 0;

				img {
					max-width: 100%;
					height: auto;
				}
			}
			.detail {
				flex-grow: 2;
				.title {
					font-size: 14px;
					line-height: 18px;
					text-transform: none;
					top: auto;		// Surcharge

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;

					i.fa {
						color: color('grey','darken-1');
						font-size: 80%;
						vertical-align: top;
					}
				}
			}

			.extern-link {
				margin-left: 3px;
				text-align: right;
				font-size: 15px;
				line-height: 22px;
			}
		}

		// Channel specific properties
		.channel {
			.detail {
				.category, .score, .subscribers {
					font-size : 12px;
					small {
						font-size : 75%;
					}
				}
			}
		}

		// Video specific properties
		.video {
			min-width: 350px;

			.thumb {
				width: 106px;
			}
		}

		// Boutons d'actions dans un tableau
		.btn.btn-flat, .btn.btn-text {
			padding : 0;
			margin: 1px 0;
			width: 24px;
			color   : #757575 !important;

			&:hover {
				background: color( 'blue-grey', 'lighten-3' );
			}
		}
	}
}

/** Table with filters **/
.table-filters {
	display: flex;
	align-items: stretch;
	flex-flow: row nowrap;

	.filters-container {
		flex-shrink: 0;
		width: 200px;
		padding: 0;
		margin: 0 0.5rem 0 0;
		position: relative;
		transition: width 0.5s ease-in-out, background 0.5s ease-in-out, box-shadow 0.5s ease-in-out;

		.filters {
			visibility: visible;
			opacity: 1;
			transition: visibility 0.5s, opacity 0.5s;
			height: 100%;
			width: 200px;

			.title {
				font-size: 18px;
			}
		}

		.collapser {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: 26px;
			cursor: pointer;
			padding: 4px 0;
			width: 20px;
			transition: 0.3s;

			&:after {
				font-family: 'Material Icons';
				font-size: 28px;
				content: 'keyboard_arrow_left';
			}
		}
	}

	&.collapsed .filters-container {
		width: 28px;

		box-shadow    : 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
		border-radius : 2px;
		background    : #ffffff;

		.collapser {
			width: 28px;
			padding-top: 9px;
			height: 100%;
			&:after {
				content: 'keyboard_arrow_right';
			}
		}

		.filters {
			visibility: hidden;
			opacity: 0;
		}
	}

	.table-container {
		flex: 1;
		overflow-x: hidden;

		&.card {
			margin-bottom: 0;
		}

		> .row:last-of-type {
			margin-bottom: 0;
		}
	}
}


/** Table tools (below/behind table) **/
.table-tools {
	padding: 8px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	// Fait en sorte que les dropdown ressemble a des 'select'
	a.dropdown:not( .btn ) {
		margin-right: 16px;
		margin-top: 18px;

		padding: 0 20px 3px 0;

		color: $text-color;
		border-bottom: $input-border;
		font-size: 14px;

		&:after {
			right: 0;
		}

		// Le sélecteur de ligne est caché par défaut
		&.btn-row-selector {
			display:none;
		}
	}

	.group-columns {
		margin-top: 12px;
		margin-right: 16px;
	}
	.group-columns-dropdown {
		margin-top: 12px;
	}

	form.material {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		.input-field.input-select {
			padding: 15px 0 0;
			margin-right: 28px;
			width: 140px;

			label {
				font-size: 12px;
			}

			input[type=text] {
				font-size: 14px;
				height: 25px;
				line-height: 14px;
			}
		}
	}

	ul.paginator {
		margin: 0 0 0 auto;

		font-size: 13px;
		float: right;

		display: flex;
		align-items: center;
		flex-shrink: 0;

		li {
			list-style: none;
			flex-shrink: 0;
			transition: .3s;

			&.total {
				margin-right: 9px;
			}

			a {
				height: 1.4rem;
				line-height: 1.4rem;
				font-weight: normal;
				display: block;
				margin: 0 0.15625rem;
				padding: 0 0.3125rem;
				border-radius: 2px;
				transition: 0.3s;
			}

			&:hover:not(.disabled):not(.total):not(.current) a {
				background: color('blue-grey', 'lighten-4');
			}

			&.current a {
				color: #ffffff;
				background-color: #757575;
			}

		}
	}

	&.top ul.paginator {
		margin-top: 12px;
	}
}

/***********************
  Table row selector
************************/
.table-row-selectable {
	// Bouton de selection des colonnes doit être visible
	a.dropdown:not(.btn-dots).btn-row-selector {
		display: block;
		font-size: 20px;
		padding: 0 20px 2px 9px;
	}

	// Ligne affichant les elements selectionnes
	tr.table-row-selected th {
		/*border-top: 1px solid #90caf9;;*/
		border-bottom: 1px solid #b0bec5;
		padding: 0;

		i:first-child {
			padding: 0 13px 0 16px;
			font-size: 20px;
			margin-top: 6px;
			display: block;
			float: left;
		}

		.row-selected {
			box-shadow: inset 0 2px 4px -2px #4e4e4e;
			background-color: #bbdefb;
			padding: 11px 9px 7px;
			display: none;
			overflow: hidden;
		}

		.row-selected-actions {
			margin-left: 12px;
		}
	}

	td.row-selector {
		width: 44px;

		& > .checkbox {
			position: relative;
			width: 20px;
			height: 20px;
			margin: auto;
			cursor: pointer;

			&:before {
				position: absolute;
				width: 0;
				height: 0;
				content: '';
				left: 6px;
				top: 10px;
				z-index: 1;

				border: 3px solid transparent;
				border-radius: 1px;
				margin-top: 2px;

				transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
				-webkit-transform: rotateZ(37deg);
				transform: rotateZ(37deg);
				-webkit-transform-origin: 20% 40%;
				transform-origin: 100% 100%;
			}

			&:after {
				position: absolute;
				height: 20px;
				width: 20px;
				top: auto;
				left: auto;
				z-index: 0;

				background-color: transparent;
				border: 2px solid $radio-empty-color;
				border-radius: 2px;
				content: '';

				/* .1s delay is for check animation */
				transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
			}

			&.checked {
				&:before {
					top: 0;
					left: 1px;
					width: 8px;
					height: 13px;

					border-top: 2px solid transparent;
					border-left: 2px solid transparent;
					border-right: 2px solid $input-background;
					border-bottom: 2px solid $input-background;

					-webkit-transform: rotateZ(37deg);
					transform: rotateZ(37deg);
					backface-visibility: hidden;
					-webkit-transform-origin: 100% 100%;
					transform-origin: 100% 100%;
				}

				&:after {
					top: 0;
					width: 20px;
					height: 20px;
					border: 2px solid $secondary-color;
					background-color: $secondary-color;
					z-index: 0;
				}
			}

			// Disabled style
			&.disabled {
				color: $input-disabled-color;
				cursor: default;

				&:before {
					border-right: 2px solid $input-disabled-color;
					border-bottom: 2px solid $input-disabled-color;
				}
			}

			&.disabled:not(:checked):before {
				background-color: transparent;
				border: 2px solid transparent;
			}

			&.disabled:not(:checked):after {
				border-color: transparent;
				background-color: $input-disabled-solid-color;
			}

			&.disabled:checked:before {
				background-color: transparent;
			}

			&.disabled:checked:after {
				background-color: $input-disabled-solid-color;
				border-color: $input-disabled-solid-color;
			}

		}
	}
}

.table-double-slider, .doubleScroll-scroll-wrapper {
	@include mini-scrollbar;
	&::-webkit-scrollbar-thumb {
		border: none;
	}
	overflow-x: auto;
	width: 100%;
}
