/* Text Inputs + Textarea
   ========================================================================== */

/* Style Placeholders */
::-webkit-input-placeholder {
	color : $placeholder-text-color;
}

:-moz-placeholder { /* Firefox 18- */
	color : $placeholder-text-color;
}

::-moz-placeholder { /* Firefox 19+ */
	color : $placeholder-text-color;
}

:-ms-input-placeholder {
	color : $placeholder-text-color;
}

// Fix label cliquable dans les modals

.modal-content{
	.input-field {
		&.input-text, &.input-email, &.input-url, &.input-password {
			label {
				z-index: -1;
			}
		}
	}
}


// Styling for input field wrapper
.input-field {
	position : relative;
	padding: 20px 0 10px 0;

	&.no-label {
		padding: 0 0 20px;
	}

	&.input-text, &.input-email, &.input-url, &.input-password, &.input-number, &.input-textarea {
		label {
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			color      : $label-font-color;
			position   : absolute;
			top        : 26px;
			left       : 0;
			font-size  : 1rem;
			cursor     : text;
			transition : .2s ease-out;

			&.active {
				font-size : $label-font-size;
				transform : translateY( -24px );
			}
		}
	}

	// Inline styles
	&.inline {
		display: inline-block;
		margin: 0 5px;
	}

	// Pour affichage des messages
	&:after {
		content   : attr(data-message);
		opacity   : 1;
		font-size : $label-font-size;
		display   : block;
		//bottom    : 0;
		//left      : 0;
		//position  : absolute;
	}

	// Affichage du sigle '%'
	.percent {
		display: flex;
		.percent-sigle {
			content: '%';
			padding-top: 7px;
			padding-left: 4px;
		}
	}

	/* Text inputs */
	input:not([type]),
	input[type=text],
	input[type=password],
	input[type=email],
	input[type=url],
	input[type=time],
	input[type=date],
	input[type=datetime],
	input[type=datetime-local],
	input[type=tel],
	input[type=number],
	input[type=search],
	textarea {

		// General Styles
		background-color : transparent;
		border           : none;
		border-bottom    : $input-border;
		border-radius    : 0;
		outline          : none;
		//height 		     : 36px;
		line-height      : 36px;

		width            : 100%;
		font-size        : $input-font-size;
		margin           : 0; //$input-margin;
		padding          : $input-padding;
		box-shadow       : none;
		box-sizing       : content-box;
		transition       : $input-transition;

		&.xsmall {
			width: 20%;
		}

		&.small {
			width: 33%;
		}

		&.large {
			width: 50%;
		}

		&.xlarge {
			width: 75%;
		}

		// Disabled input style
		&:disabled,
		&[readonly="readonly"] {
			color         : $input-disabled-color;
			border-bottom : $input-disabled-border;
		}

		// Disabled label style
		&:disabled + label,
		&[readonly="readonly"] + label {
			color : $input-disabled-color;
		}

		// Focused input style
		&:focus:not([readonly]) {
			border-bottom : 1px solid $input-focus-color;
			box-shadow    : 0 1px 0 0 $input-focus-color;
		}

		&::placeholder {
			font-style: italic;
		}
	}

	// Cas particulier du number ou on a besoin d'un leger padding pour ne pas coller au bord
	input[type=number] {
		padding-right: 5px;
	}

	// Valid Input Style
	&.success {
		input:not([type]),
		input[type=text],
		input[type=password],
		input[type=email],
		input[type=url],
		input[type=time],
		input[type=date],
		input[type=datetime],
		input[type=datetime-local],
		input[type=tel],
		input[type=number],
		input[type=search],
		textarea {
			border-bottom : 1px solid $input-success-color !important;
			box-shadow    : 0 1px 0 0 $input-success-color !important;
		}

		// Custom Success Message
		&:after {
			color     : $input-success-color;
		}

		.error-message {
			color     : $input-success-color;
			font-size : $label-font-size;
		}
	}

	// Error
	&.error {
		input:not([type]),
		input[type=text],
		input[type=password],
		input[type=email],
		input[type=url],
		input[type=time],
		input[type=date],
		input[type=datetime],
		input[type=datetime-local],
		input[type=tel],
		input[type=number],
		input[type=search],
		textarea {
			border-bottom : 1px solid $input-error-color !important;
			box-shadow    : 0 1px 0 0 $input-error-color !important;
		}

		// Custom Error Message
		&:after {
			color: $input-error-color;
		}

		.error-message {
			color     : $input-error-color;
			font-size : $label-font-size;
		}
	}



	// Prefix Icons
/*
	.prefix {
		position   : absolute;
		width      : $input-height;
		font-size  : 2rem;
		transition : color .2s;

		&.active { color : $input-focus-color; }
	}

	.prefix ~ input,
	.prefix ~ textarea,
	.prefix ~ label,
	.prefix ~ .validate ~ label,
	.prefix ~ .autocomplete-content {
		margin-left : 3rem;
		width       : 92%;
		width       : calc(100% - 3rem);
	}

	.prefix ~ label { margin-left : 3rem; }

	@media #{$medium-and-down} {
		.prefix ~ input {
			width : 86%;
			width : calc(100% - 3rem);
		}
	}

	@media #{$small-and-down} {
		.prefix ~ input {
			width : 80%;
			width : calc(100% - 3rem);
		}
	}
*/
}


/* Search Field */
/* Pas decrit dans le Framework, donc inutile ?
.input-field input[type=search] {
	display      : block;
	line-height  : inherit;
	padding-left : 4rem;
	width        : calc(100% - 4rem);

	&:focus {
		background-color : $input-background;
		border           : 0;
		box-shadow       : none;
		color            : #444444;

		& + label i,
		& ~ .mdi-navigation-close,
		& ~ .material-icons {
			color : #444444;
		}
	}

	& + label {
		left : 1rem;
	}

	& ~ .mdi-navigation-close,
	& ~ .material-icons {
		position   : absolute;
		top        : 0;
		right      : 1rem;
		color      : transparent;
		cursor     : pointer;
		font-size  : 2rem;
		transition : .3s color;
	}
}
*/

/* Autocomplete */
.autocomplete-content {
	margin-top : -15px;
	display    : block;
	opacity    : 1;
	position   : static;

	li {
		.highlight { color : #444444; }

		img {
			height : $dropdown-item-height - 10;
			width  : $dropdown-item-height - 10;
			margin : 5px 15px;
		}
	}
}
