.container {
    background-color: white;
    height: 100%;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
}

.month {
    margin-top: 10px;
    &:last-child {
    margin-bottom: 10px;
}}

.label {
    font-size: 16px;
}

.value {
    text-align: right;
    margin-top: 5px;
    font-weight: 500;
    font-size: 18px;
}
