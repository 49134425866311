/** Header du site **/
#header {
	position: fixed;
	top: 0;
	left:0;
	right:0;
	z-index: 1020;

	background-color: $mainheader-bg-color;
	color: #fff;

	.content {
		margin: 0;
		padding: 0;
		display:flex;
		justify-content: space-between;
	}

	.navbar-header {
		height: 100%;
		@media #{$medium-and-up} {
			float: left;
		}

		@media #{$small-and-down} {
			border-bottom: 1px solid #3c4253;
		}

		&:after, &:before {
			content: '';
			display: table;
			clear: both;
		}
		.navbar-brand {
			background: url('../../../assets/img/header-logo.png') center center no-repeat;
			display: block;
			width: 220px;
			height: 60px;
			margin: 0;

			.navbar-title {
				display: none;
			}
		}

		.sidebar-toggle {
			@media #{$medium-and-up} {
				display: none;
			}

			position: relative;
			float: right;
			padding: 10px;
			margin-top: 15px;
			margin-right: 15px;
			margin-bottom: 8px;
			background-color: transparent;
			background-image: none;
			border: none;
			border-radius: 4px;
			outline: 0;

			.icon-bar {
				background: #fff;
				display: block;
				width: 22px;
				height: 2px;
				border-radius: 1px;
				&+.icon-bar {
					margin-top: 4px;
				}
			}
		}
	}

	.navbar-nav {
		height: 60px;

		ul.nav > li {
			padding: 0;
			margin: 0;
			&:not(.drop) a {
				padding: 18px 16px 15px;
			}
			&.drop {
				padding: 18px 16px 15px;
				padding-right: 32px;
				&:after {
					line-height: 60px;
				}

				& > ul {
					top: 60px;
				}
			}
		}
	}
}

body.no-sidebar {
	#header {
		.content {
			margin: 0 auto;
			padding: 0 8px;
		}
		.sidebar-toggle {
			display: none;
		}
		.navbar-header .navbar-brand {
			background-position: left center;
		}
	}

	/*
	header {
		padding: 0 10%;
	}*/

	@media #{$medium-and-up} {
		#header .content {
			width : 95%;
		}
	}

	@media #{$extra-large-and-up} {
		#header .content {
			width : 85%;
			max-width: 1280px;
		}
	}

	@media #{$double-extra-large-and-up} {
		#header .content {
			width : 80%;
			max-width: 1280px;
		}
	}
}


header {
	padding    : 16px;
	position   : relative;
	font-size  : $font-size;
	color      : white;
	background-color : $header-bg-color;

	// Par défaut, la couleur des liens est inversés
	a {
		color: white;
		&:hover {
			color: color( 'blue', 'lighten-4' );
		}
	}

	h1, h2, h3, h4, h5, h6 {
		small {
			color: color('grey', 'lighten-1' );
		}
	}

	&.danger, &.error, &.warning, &.success {
		&:after {
			font-family: "Font Awesome\ 5 Pro";
			content: "\f071";
			position: absolute;
			word-wrap: normal;
			top: 9px;
			right: 9px;
			color: rgba( 255,255,255,0.5);
			font-size: 64px;
			line-height: 64px;
			font-style: normal;
		}
	}
	&.danger, &.error {
		background-color: $error-color;
		&:after {
			content: "\f071";
		}
	}

	&.warning {
		background-color: $warning-color;
		&:after {
			content: "\f06a";
		}
	}

	&.success {
		background-color: $success-color;
		&:after {
			content: "\f00c";
		}
	}

	.inline-actions {
		position : absolute;
		top      : 24px;
		right    : 24px;
	}

	.actions {
		position : absolute;
		bottom   : 0;
		right    : 0;

		&.with-navbar {
			bottom: -50px;
		}
	}

	.btn-floating {
		right : $btnDefault;
		top   : $btnDefault/2;

		&.btn-xsmall {
			right : $btnXsmall;
			top   : $btnXsmall/2;
		}

		&.btn-small {
			right : $btnSmall;
			top   : $btnSmall/2;
		}

		&.btn-large {
			right : $bntLarge;
			top   : $bntLarge/2;
		}

		&.btn-xlarge {
			right : $btnXlarge;
			top   : $btnXlarge/2;
		}
	}
}


body.no-sidebar {
	header {
		.container {
			padding: 0 9px;
		}
	}
}

body:not(.no-sidebar) {
	header {
		.container {
			width: auto;
			max-width: none;
			padding: 0;
		}
	}
}
