
.ranking {
	tr {
		height: 64px;

		&.highlight {
			background: #1e88e5 !important;

			.positive {
				color: color('green', 'accent-4') !important;
			}
			.negative {
				color: color('red', 'darken-4') !important;
			}

			td, td a {
				color: #ffffff !important;
			}
		}

		td {
			&.rank {
				font-size: 19px;
				font-weight: bold;
			}

			&.ranking-evo {
				padding-right: 0;
				font-size: 20px;
				text-align: center;
			}

			&.logo {
				min-width: 62px;
				text-align: center;

				img {
					width: 44px;
					height: 44px;
					border-radius: 100%;
				}
			}

			&.name {
				font-size: 17px;
				font-weight: bold;
				width: 100%;
				a {
					color: $text-color;
				}
			}

			&.kpi {
				text-align: right;
				font-weight: bold;
				font-size: 17px;
			}
		}
	}
}