/*************************
  Scrollbar design mixin *
*************************/
@mixin mini-scrollbar {
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: color('grey', 'lighten-1');
	}
	&::-webkit-scrollbar-track {
		background-color: color('grey', 'lighten-3');
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid color('grey', 'lighten-3');
		background-color: color('grey', 'lighten-1');
		&:hover {
			background-color: color('blue', 'lighten-1');
			@include transition(all,0.75s);
		}
	}
}
