#cookie-consent {
	background-color: bisque;
	border-top: 1px solid color( 'grey', 'lighten-1' );
	padding: 16px 0;
	position: fixed;
	z-index: 1050;
	bottom: 0;
	width: 100%;
	display: none;

	.container {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
}
