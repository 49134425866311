
// General style
$font-size: 	18px !default;
$line-height: 	21px !default;
$text-color: 	rgba(0,0,0,0.71) !default;

$link-color: 		color( 'blue', 'base' ) !default;

$secondary-color: $link-color !default;

$error-color: 		#d32f2f !default;
$success-color: 	#388e3c !default;
$warning-color:	#fb8c00 !default;
$info-color:		color( 'cyan', 'darken-1');

// Couleurs des univers
$youtubers-color: #c62828;
$brands-color: #2277ba;
$networks-color: #ef6c00;

// Couleur des réseaux sociaux
$creator-color: #333;
$youtube-color: #CC181E;
$gplus-color: #d34836;
$facebook-color: #3a589e;
$twitter-color: #2B84B4;
$instagram-color: #c2185b;
$twitch-color: #6441a4;


// Media Query Ranges
$xsmall-screen-up: 577px !default;
$small-screen-up: 769px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$xlarge-screen-up: 1381px !default;

$xsmall-screen: 576px !default;
$small-screen: 768px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$xlarge-screen: 1380px !default;

$small-and-up: "only screen and (min-width : #{$xsmall-screen-up})" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$double-extra-large-and-up: "only screen and (min-width : #{$xlarge-screen-up})" !default;

$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$large-and-down: "only screen and (max-width : #{$large-screen})" !default;
$extra-large-and-down: "only screen and (max-width : #{$xlarge-screen})" !default;

$xsmall-only: "only screen and (max-width : #{$xsmall-screen})" !default;
$small-only: "only screen and (min-width : #{$xsmall-screen-up}) and (max-width : #{$small-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$large-only: "only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})" !default;
$xlarge-only: "only screen and (min-width : #{$large-screen-up}) and (max-width : #{$xlarge-screen})" !default;
$xxlarge-only: "only screen and (min-width : #{$double-extra-large-and-up})" !default;


// Grid
$num-cols: 12 !default;
$gutter-width:0.8rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


// Dropdown
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;

// Input
$input-height: 2rem !default;
$input-border-color:  #9e9e9e !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
$input-font-size: 16px !default;
$input-margin: 0 0 36px 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: .9rem !default;
$label-font-color: rgba(0,0,0, .5) !default;
$input-disabled-color: rgba(0,0,0, .25) !default;
$input-disabled-solid-color: #BDBDBD !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: rgba(0,0,0, .30) !default; //lighten($input-border-color, 20%) !default;

// Radio Buttons
$radio-fill-color: $secondary-color !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switch
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: #90caf9; //desaturate(lighten($secondary-color, 25%), 25%) !default;
$switch-disabled-checked-lever-bg: #bbdefb;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;
$switch-radius: 15px !default;

// Floating button
$btnXsmall : 20px;
$btnSmall :30px;
$btnDefault : 40px;
$bntLarge : 50px;
$btnXlarge : 60px;

// Header
$mainheader-bg-color: #303646;
$header-bg-color: color( 'blue', 'darken-2');

// Sidenav
$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0,0,0,.87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-header-bg-color: #11293F !default;
// Datepicker
$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;


// Tabs
$tabs-underline-color: $link-color !default;
$tabs-text-color: $link-color !default;
$tabs-bg-color: transparent !default;

// Navigation Bar
$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
$navbar-font-color: #000 !default;

$navbar-active-color: color( 'light-blue', 'lighten-2' ) !default;
$navbar-active-background-color: color( 'light-blue', 'lighten-5' );
$navbar-brand-font-size: 2.1rem !default;
