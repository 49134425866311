#header {
	position: fixed;
	top: 0;
	left:0;
	right:0;
	z-index: 1020;

	background-color: $mainheader-bg-color;
	color: #fff;

	.navbar-header {
		height: 100%;
		@media #{$medium-and-up} {
			float: left;
		}

		@media #{$small-and-down} {
			border-bottom: 1px solid #3c4253;
		}

		&:after, &:before {
			content: '';
			display: table;
			clear: both;
		}
		.navbar-brand {
			background: url('../../../assets/img/header-logo.png') center center no-repeat;
			display: block;
			width: 160px;
			height: 60px;
			margin: 0 3px;

			float: left;

			.navbar-title {
				display: none;
			}
		}

		.sidebar-toggle {
			@media #{$medium-and-up} {
				display: none;
			}

			position: relative;
			float: right;
			padding: 10px;
			margin-top: 15px;
			margin-right: 15px;
			margin-bottom: 8px;
			background-color: transparent;
			background-image: none;
			border: none;
			border-radius: 4px;
			outline: 0;

			.icon-bar {
				background: #fff;
				display: block;
				width: 22px;
				height: 2px;
				border-radius: 1px;
				&+.icon-bar {
					margin-top: 4px;
				}
			}
		}
	}

	.navbar-nav {
		float: right;

		ul.nav > li {
			padding: 18px 16px 15px;
			&.drop {
				padding-right: 32px;
				&:after {
					line-height: 60px;
				}

				& > ul {
					top: 60px;
				}
			}
		}
	}
}
