/* Select Field
   ========================================================================== */

.input-field.input-select {
	padding-right: 12px;
	label {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		color      : $label-font-color;
		font-size  : $label-font-size;
		position   : absolute;
		top        : 2px;
		left       : 0;
		cursor     : pointer;
		transition : .2s ease-out;
	}
}

// Surcharge ancien style
.validate {
	margin: 0;
}

select {
	display: none;
}

select.browser-default, select.selectize {
	display: block;
}

select {
	background-color: $select-background;
	width: 100%;
	padding: $select-padding;
	border: $select-border;
	border-radius: $select-radius;
	height: $input-height;
}

.select-label {
	position: absolute;
}

.select-wrapper {
	position: relative;

	input.select-dropdown {
		position: relative;
		cursor: pointer;
		background-color: transparent;
		border: none;
		border-bottom: $input-border;
		outline: none;
		//height: $input-height;
		line-height: $input-height;
		width: 100%;
		font-size: $input-font-size;
		margin: 0; //$input-margin;
		padding: 0 12px 0 0;
		display: block;
	}
	span.caret {
		color: initial;
		position: absolute;
		right: -12px;
		top: 0;
		bottom: 0;
		height: 10px;
		margin: auto 0;
		font-size: 11px;
		line-height: 11px;

		&.disabled {
			color: $input-disabled-color;
		}
	}

/*	& + label {
		position: absolute;
		top: -14px;
		font-size: $label-font-size;
	}*/

	.dropdown-content {
		li {
			cursor: pointer;

			&.optgroup, &.disabled {
				cursor:default;
			}

			& > a, & > span {
				padding: 6px 16px 6px 6px;
			}
		}

		&.multiple-select-dropdown {
			label {
				//padding-left: 16px !important;
				overflow: inherit;
				width: auto;
				white-space: normal;
				padding: 0;
				margin: 0 28px 0 2px;
				top: 4px !important;
				position: relative;
				height: 20px;
			}
		}

		&:not( .multiple-select-dropdown ) li:not( .no-value ):not( .optgroup ) {
			position: relative;

			&.selected {
				background-color: $dropdown-hover-bg-color;
			}

			& > a:before, & > span:before {
				color: rgba(0, 0, 0, 0.54);
				display: block;

				width: 24px;
				margin-right: 6px;
				font-size: 24px;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				content: " ";
				font-style: normal;
				text-transform: none;
				//overflow: hidden;
				top: 6px;
				left: 6px;
				position: absolute;
			}
			&.selected:not(.disabled) > span:before,
			&.selected:not(.disabled) > a:before {
				font-family: "Material Icons";
				content: "done";
			}
			& > a, & > span {
				padding: 6px 16px 6px 36px;
			}
		}
	}
}

// Disabled styles
select:disabled {
	color: rgba(0, 0, 0, .3);
}

.select-wrapper input.select-dropdown:disabled {
	color: rgba(0, 0, 0, .3);
	cursor: default;
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-ms-user-select: none; /* IE10+ */
	border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.select-wrapper i {
	color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
	color: $select-disabled-color;
	background-color: transparent;
}

// Prefix Icons
/*
.prefix ~ .select-wrapper {
	margin-left: 3rem;
	width: 92%;
	width: calc(100% - 3rem);
}

.prefix ~ label {
	margin-left: 3rem;
}
*/

// Icons
/*
.select-dropdown li {
	img {
		height: $dropdown-item-height - 10;
		width: $dropdown-item-height - 10;
		margin: 5px 15px;
		float: right;
	}
}
*/
// Optgroup styles
/*
.select-dropdown li.optgroup {
	border-top: 1px solid $dropdown-hover-bg-color;

	&.selected > span {
		color: rgba(0, 0, 0, .7);
	}

	& > span {
		color: rgba(0, 0, 0, .4);
	}
}
*/
