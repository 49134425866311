

.title-news-features{
	float: left;
	position: absolute;
	height: 54px;
	width: 137px;
	font-size: 18px;
	font-weight: bold;
	line-height: 49px;
	white-space: nowrap;
	padding: 2px 5px;
	text-align: center;
	box-sizing: border-box;
	border-radius: 2px 0px 0px 2px;
	background-color: #2196f3;
	color: #fff;
}

.content-news{
	padding-left: 150px !important;
	overflow: hidden;
	max-height: 55px!important;
	padding: 12px 16px;
	position: relative;
	flex-grow: 1;

	#prev-button,#next-button{
		font-size: 18px;
		line-height: 28px;
		cursor: pointer;
	}
}
ul.ticker{
	margin-bottom: 0;
	font-size: 17px;
	max-height: 28px!important;
	overflow: hidden;
	line-height: 28px!important;
	list-style-type: none;
	margin-left: 15px!important;
	max-width: 80%;
	li{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}


@media only screen and (min-width: 993px){
	.content-news .news{
		width: 90%;
	}
}

@media only screen and (max-width: 640px){
	.content-news .news{
		width: 65%;
	}
	ul.ticker{
		max-width: 60%;
	}
}

.card-message-news-features-home .container-message{
	position: relative;
	background: #011a27 !important;
	display: flex;
	flex-direction: column;
	.title-news-features {
		float: left;
		position: absolute;
		height: 34px;
		width: 90px;
		font-size: 18px;
		font-weight: bold;
		line-height: 28px;
		white-space: nowrap;
		padding: 2px 5px;
		text-align: center;
		box-sizing: border-box;
		border-radius: 2px 0px 0px 2px;
		background-color: #2196f3;
		color: #fff;
	}
	.content-news {
		padding-left: 108px !important;
		overflow: hidden;
		max-height: 55px !important;
		padding: 3px;
		position: relative;
		flex-grow: 1;
	}
	#removeNewsFeature{
		line-height: 28px;
		margin-right: 6px;
		color: white;
	}
}

