.container, .container-large, .container-full {
	margin     : 0 auto;
	background : transparent !important;
	padding: 9px;
}

@media #{$medium-and-up} {
	.container {
		width : 95%;
	}
}

@media #{$large-and-up} {
	.container {
		width: 90%;
	}
	.container-large {
		width: 95%;
	}
}

@media #{$extra-large-and-up} {
	.container {
		width: 85%;
		max-width: 1280px;
	}
	.container-large {
		width: 90%;
	}
}

@media #{$double-extra-large-and-up} {
	.container {
		width: 80%;
		max-width: 1280px;
	}
	.container-large {
		width: 86%;
	}
}

body.no-sidebar {
	@media #{$large-and-up} {
		.container-large {
			width: 90%;
		}
	}

	@media #{$extra-large-and-up} {
		.container-large {
			width: 85%;
			max-width: 1280px;
		}
	}

	@media #{$double-extra-large-and-up} {
		.container-large {
			width: 80%;
			max-width: 1280px;
		}
	}
}


.container .row, .container-large .row, .container-full .row {
	margin-left  : (-1 * $gutter-width / 2);
	margin-right : (-1 * $gutter-width / 2);
}

.section {
	padding-top    : 1rem;
	padding-bottom : 1rem;
}

// Mixins to eliminate code repitition
@mixin reset-offset {
	margin-left: auto;
	left: auto;
	right: auto;
}
@mixin grid-classes($size, $i, $perc) {
	&.offset-#{$size}#{$i} {
		margin-left: $perc;
	}
	&.pull-#{$size}#{$i} {
		right: $perc;
	}
	&.push-#{$size}#{$i} {
		left: $perc;
	}
}

.row {
	margin-left   : auto;
	margin-right  : auto;
	margin-bottom : 10px;

	// Clear floating children
	&:after {
		content : "";
		display : table;
		clear   : both;
	}

	.col {
		float      : left;
		box-sizing : border-box;
		padding    : 0 $gutter-width / 2;
		min-height : 1px;

		&[class*="push-"],
		&[class*="pull-"] {
			position : relative;
		}

		$i: 1;
		@while $i <= $num-cols {
			$perc: unquote((100 / ($num-cols / $i)) + "%");
			&.xs#{i}, &.s#{$i} {
				width: $perc;
				@include reset-offset;
			}
			$i: $i + 1;
		}

		$i: 1;
		@while $i <= $num-cols {
			$perc: unquote((100 / ($num-cols / $i)) + "%");
			@include grid-classes("xs", $i, $perc);
			$i: $i + 1;
		}

		@media #{$small-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.s#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("s", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$medium-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.m#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("m", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$large-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.l#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("l", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$extra-large-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.xl#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("xl", $i, $perc);
				$i: $i + 1;
			}
		}

		@media #{$double-extra-large-and-up} {
			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				&.xxl#{$i} {
					width: $perc;
					@include reset-offset;
				}
				$i: $i + 1;
			}

			$i: 1;
			@while $i <= $num-cols {
				$perc: unquote((100 / ($num-cols / $i)) + "%");
				@include grid-classes("xxl", $i, $perc);
				$i: $i + 1;
			}
		}
	}
}
