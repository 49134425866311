$h1-fontsize: 2.20rem;
$h2-fontsize: 1.80rem;
$h3-fontsize: 1.50rem;
$h4-fontsize: 1.35rem;
$h5-fontsize: 1.20rem;
$h6-fontsize: 1.10rem;

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  color:inherit;
  -webkit-font-smoothing: antialiased;
  //margin: 0.9rem 0 0.7rem 0 !important;
  text-transform: none;
  font-weight: 400;
  line-height: 1.1;

	small {
		color: color('grey', 'darken-1' );
	}
}

h1 {
  font-size: $h1-fontsize;
  margin: ( $h1-fontsize / 2.5) 0 ( $h1-fontsize / 2.5 ) 0;
}

h2 {
  font-size: $h2-fontsize;
  margin: ( $h2-fontsize / 2.5) 0 ( $h2-fontsize / 2.5 ) 0;
}

h3 {
  font-size: $h3-fontsize;
  margin: ( $h3-fontsize / 2.5) 0 ( $h3-fontsize / 2.5 ) 0;
}

h4 {
  font-size: $h4-fontsize;
  margin: ( $h4-fontsize / 2.5) 0 ( $h4-fontsize / 2.5 ) 0;
}

h5 {
  font-size: $h5-fontsize;
  margin: ( $h5-fontsize / 2.5) 0 ( $h5-fontsize / 2.5 ) 0;
}

h6 {
  font-size: $h6-fontsize;
  margin: ( $h6-fontsize / 2.5) 0 ( $h6-fontsize / 2.5 ) 0;
}
