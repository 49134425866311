.gauge {
	position: relative;
	font-size: 90px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: #ccc;
	display: inline-block;

	.desc {
		font-size: inherit;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.name {
			text-align: center;
			font-size: .26em;
			line-height: normal;
			color: #307bbb;
			&.text-blur {
				color: transparent !important;
				text-shadow: RGB(0 0 0 / 100%) 0 0 5px;
			}
		}

		.title {
			text-align: center;
			font-size: .18em;
			line-height: normal;
			color: #666;
			&.text-blur {
				color: transparent !important;
				text-shadow: RGB(0 0 0 / 100%) 0 0 5px;
			}
		}
	}

	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
		&.halfed {
			clip: rect(auto,auto,auto,auto);
			.fill {
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}

		.bar {
			position: absolute;
			border: .09em solid #307bbb;
			width: .82em;
			height: .82em;
			clip: rect(0em, 0.5em, 1em, 0em);

			border-radius: 50%;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		.fill {
			position: absolute;
			border: .09em solid #307bbb;
			width: .82em;
			height: .82em;
			clip: rect(0em,0.5em,1em,0em);
			border-radius: 50%;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		& *, & *:before, & *:after {
			-webkit-box-sizing: content-box;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
		}
	}

	&:after {
		position: absolute;
		top: .09em;
		left: .09em;
		display: block;
		content: " ";
		border-radius: 50%;
		background-color: #f5f5f5;
		width: .82em;
		height: .82em;
	}

	&.large {
		font-size: 120px;
	}

	&.small {
		font-size: 55px;
		.bar, .fill {
			border: .11em solid #307bbb;
			width: .78em;
			height: .78em;
		}
		&:after {
			top: .11em;
			left: .11em;
			width: .78em;
			height: .78em;
		}
	}

	&.xsmall {
		font-size: 30px;

		.bar, .fill {
			border: .13em solid #307bbb;
			width: .74em;
			height: .74em;
		}

		&:after {
			top: .13em;
			left: .13em;
			width: .74em;
			height: .74em;
		}

		.desc {
			.title {
				display: none
			}

			.name {
				font-size: .36em;
				font-weight: bold;
			}
		}
	}

	&.danger {
		.desc .name {
			color: $error-color;
		}
		.slice {
			.fill, .bar {
				border-color: $error-color;
			}
		}
	}

	&.success {
		.desc .name {
			color: $success-color;
		}
		.slice {
			.fill, .bar {
				border-color: $success-color;
			}
		}
	}

	&.warning {
		.desc .name {
			color: $warning-color;
		}
		.slice {
			.fill, .bar {
				border-color: $warning-color;
			}
		}
	}
}
