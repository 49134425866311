@import '../../../styles/_variables.scss';

.BrainhubCarousel__container {
    flex: 1;

    .BrainhubCarousel,
    .BrainhubCarousel__trackContainer,
    .BrainhubCarousel__track,
    .BrainhubCarouselItem {
        height: 100%;
    }

    .BrainhubCarouselItem {
        padding: 0px;
    }
}

.dots {
    padding: 10px;

    li div:before {
        width: 8px !important;
        height: 8px !important;
        background: $primary !important;
    }
}
