.logo {
    border-radius: 100%;
    height: 50px;
    aspect-ratio: 1;
}

.infos_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    overflow: hidden;

    .fullname, .email {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fullname {
        font-size: 16px;
        font-weight: 500;
    }

    .email {
        font-size: 12px;
    }
}
