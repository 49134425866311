.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0px;
}

.header {
    background-position: center;
    background-size: cover;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    padding: 0px 10px;
    min-height: 100px;
}

.infos {
    display: flex;
    align-items: center;
	 margin-bottom: 10px;
}

.name {
    font-weight: 500;
    color: white;
	 margin: 0;
}

.logo {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.kpis {
    text-align: right;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .value {
        font-weight: 500;
    }
}
